import * as api from './apiConfig';

export function checkAuthToken() {
  return api.get('API/UserAuth/CheckAuthToken').then(response => response.data);
}

export function getEmailVerificationCode() {
  return api
    .get('API/UserAuth/GetEmailVerificationCode')
    .then(response => response.data);
}

export function verifyEmail(code) {
  return api
    .get('API/UserAuth/VerifyEmail?code=' + encodeURIComponent(code))
    .then(response => response.data);
}

export function getTextVerificationCode() {
  return api
    .get('API/UserAuth/GetTextVerificationCode')
    .then(response => response.data);
}

export function verifyText(code) {
  return api
    .get('API/UserAuth/VerifyText?code=' + encodeURIComponent(code))
    .then(response => response.data);
}

export function registerUser(
  promoCode,
  email,
  password,
  firstName,
  lastName,
  phone,
  sendEmail
) {
  return api
    .post('API/UserAuth/Register?sendEmail=' + (sendEmail ? 'true' : 'false'), {
      promoCode,
      email,
      password,
      firstName,
      lastName,
      phone
    })
    .then(response => response.data);
}

export function logInUser(email, password) {
  return api
    .post('API/UserAuth/LogIn', {
      email,
      password
    })
    .then(response => {
      const authState = response.data;
      if (authState && authState.NewAuthToken) {
        api.setAuthToken(authState.NewAuthToken);
      }

      return authState;
    });
}

export function logOutUser() {
  return api.post('API/UserAuth/LogOut').then(response => {
    return true;
  });
}

export function getProfileImagePath(profileImage) {
  return (
    api.getApiUrl() +
    'FileDownload/ProfileImage/' +
    btoa(
      api.getAuthHeaders().d + '|' + api.getAuthHeaders().t + '|' + profileImage
    )
  );
}

export function updateUser(
  email,
  phone,
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
  diplomaFirstName,
  diplomaLastName
) {
  return api
    .post('API/UserAuth/UpdateUser', {
      Email: email,
      Phone: phone,
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      City: city,
      State: state,
      Zip: zip,
      DiplomaFirstName: diplomaFirstName,
      DiplomaLastName: diplomaLastName
    })
    .then(response => {
      return response.data;
    });
}

export function listUserNotification(showAll) {
  return api
    .get(
      'API/UserAuth/ListUserNotification?showAll=' +
        (showAll ? 'true' : 'false')
    )
    .then(response => {
      return response.data;
    });
}

export function savePayPalPayment(request) {
  return api.post('API/UserAuth/SavePayment', request).then(response => {
    return response.data;
  });
}

export function trackDocSigned(reference) {
  return api
    .post(
      'API/UserAuth/TrackDocSigned?reference=' + encodeURIComponent(reference)
    )
    .then(response => {
      return response.data;
    });
}

export function requestPasswordReset(email) {
  return api
    .post(
      'API/UserAuth/RequestPasswordReset?email=' + encodeURIComponent(email)
    )
    .then(response => {
      return response.data;
    });
}

export function resetPassword(email, code, password) {
  return api
    .post(
      'API/UserAuth/ResetPassword?email=' +
        encodeURIComponent(email) +
        '&code=' +
        encodeURIComponent(code) +
        '&password=' +
        encodeURIComponent(password)
    )
    .then(response => {
      return response.data;
    });
}

export function listJobListing(password) {
  return api
    .get('API/UserAuth/ListJobListing?password=' + encodeURIComponent(password))
    .then(response => {
      return response.data;
    });
}

export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function logInUserTest(t, v, tv, u) {
  return api
    .post(
      'API/UserAuth/TestLogin?t=' +
        encodeURIComponent(t) +
        '&v=' +
        encodeURIComponent(v) +
        '&tv=' +
        encodeURIComponent(tv) +
        '&u=' +
        encodeURIComponent(u)
    )
    .then(response => {
      const authState = response.data;
      if (authState && authState.NewAuthToken) {
        api.setAuthToken(authState.NewAuthToken);
      }

      return authState;
    });
}
