import React, { useState, useEffect, useRef } from "react";
import * as PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Tooltip from "@mui/material/Tooltip";

function GridLinkButton(params) {
  return (
    <a
      href={!params.colDef.onCellClicked ? params.value : null}
      target={!params.colDef.onCellClicked ? "_blank" : "_self"}
      rel="noreferrer"
      className="link"
    >
      {params.colDef.linkLabel ? params.colDef.linkLabel : params.value}{" "}
      <span
        style={{
          position: "absolute",
          right: "0px",
          display: "block",
          top: "-3px",
          bottom: "0px",
          width: "30px",
          height: "100%",
          textAlign: "right",
        }}
      >
        {!params.colDef.onCellClicked ? (
          <OpenInNewIcon style={{ fontSize: "16px", background: "#fff" }} />
        ) : null}
      </span>
    </a>
  );
}

GridLinkButton.propTypes = {
  params: PropTypes.object,
};

export default GridLinkButton;
