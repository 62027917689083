import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCookie } from 'api/apiConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../redux/actions/authActions';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TestUser({ actions }) {
  let query = useQuery();
  const [loginFailed, setLoginFailed] = useState(null);

  useEffect(() => {
    if (!getCookie('adi-t')) {
      const t = query.get('t');
      const v = query.get('v');
      const tv = getCookie('adi-visitor');
      const u = query.get('u');

      if (t && v && tv && u) {
        actions.testUserLogin(t, v, tv, u).then(result => {
          if (result.IsLoggedIn) {
            window.location = '/';
          } else {
            setLoginFailed(true);
          }
        });
      }
    }
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      {loginFailed
        ? 'There was an error logging in. Please close the window and try again, or contact support for assistance.'
        : getCookie('adi-t')
        ? 'Please use private browser or incognito mode for testing'
        : 'Checking access...'}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestUser);
