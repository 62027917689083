import React from "react";
import * as PropTypes from "prop-types";
import { Button } from "@mui/material";

function GridButton(props) {
  let link = "";
  if (props.colDef && props.colDef.linkFunc) {
    link = props.colDef.linkFunc(props);
  }

  let disabled = false;
  if (props.colDef && props.colDef.disableFunc) {
    disabled = props.colDef.disableFunc(props);
  }

  return props.value ? (
    <Button
      variant="outlined"
      href={link}
      target="_blank"
      disabled={disabled}
      className="mi-button"
    >
      {props.value}
    </Button>
  ) : null;
}

GridButton.propTypes = {
  params: PropTypes.object,
};

export default GridButton;
