import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { sendUserNotification } from '../../api/adminDataApi';

import ViewAllNotifications from '../ViewAllNotifications';

import './index.css';

function NotificationsPopup({ student, closeFunc, onUserAdded }) {
  const [notificationType, setNotificationType] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [externalUrl, setExternalUrl] = useState(null);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="user-notifications-popup">
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        {student.FirstName} {student.LastName}&apos;s Notifications
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <div className="send-new-notification-container">
          <div className="send-notification-form">
            <p>
              You can send {student.FirstName} a new notification by using this
              form.
            </p>
            <div className="notification-type">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Send user to:
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={notificationType}
                  label="Send user to:"
                  onChange={event => setNotificationType(event.target.value)}>
                  <MenuItem value={'/'}>User Dashboard</MenuItem>
                  <MenuItem value={'/my-profile'}>User Profile</MenuItem>
                  <MenuItem value={'/curriculum'}>User Curriculum</MenuItem>
                  <MenuItem value={'/transcript'}>User Transcript</MenuItem>
                  <MenuItem value={''}>External Site</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="new-notification-message">
              {notificationType === '' ? (
                <div className="external-url">
                  <TextField
                    id="standard-basic"
                    label="External URL"
                    variant="outlined"
                    className="text-field"
                    onChange={event => setExternalUrl(event.target.value)}
                    value={externalUrl}
                    fullWidth
                    placeholder="https://"
                  />
                </div>
              ) : null}

              <div className="message">
                <TextField
                  id="standard-basic"
                  label="Notification Message"
                  variant="outlined"
                  className="text-field"
                  onChange={event => setNotificationMessage(event.target.value)}
                  value={notificationMessage}
                  fullWidth
                  multiline
                />
              </div>

              <div className="send-new-notification-button">
                <Button
                  variant="contained"
                  disabled={
                    !notificationMessage ||
                    (notificationType === ''
                      ? !externalUrl || externalUrl.indexOf('http') !== 0
                      : !notificationType)
                  }
                  onClick={() => {
                    sendUserNotification({
                      UserID: student.UserID,
                      Message: notificationMessage,
                      Route:
                        notificationType.indexOf('/') === 0
                          ? notificationType
                          : null,
                      Url:
                        notificationType.indexOf('/') !== 0 ? externalUrl : null
                    }).then(result => {
                      setNotificationType(null);
                      setNotificationMessage(null);
                      setExternalUrl(null);
                      closeFunc();
                    });
                  }}>
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
        <ViewAllNotifications isAdmin userID={student.UserID} />
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NotificationsPopup;
