import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress
} from '@mui/material';
import { getApiUrl, getAuthHeaders } from '../../api/apiConfig';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '../Grid';
import GridButton from '../Grid/GridButton';
import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper
} from '@mui/material';

import {
  deleteJobListing,
  importJobListing,
  listJobListing
} from '../../api/adminDataApi';

import './index.css';

function ActivationWarningPopup({ closeFunc, confirmFunc, student }) {
  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        className="activation-warning-popup">
        <DialogTitle id="form-dialog-title">
          <IconButton
            style={{ float: 'right' }}
            aria-label="close"
            onClick={() => {
              closeFunc();
            }}>
            <CloseIcon />
          </IconButton>
          Please Confirm
        </DialogTitle>
        <DialogContent width="100%" style={{ paddingTop: '20px' }}>
          <p>Are you sure you want to allow this user access?</p>
          <ul>
            {student.warningList.map((warning, warningIndex) => (
              <li key={warningIndex}>{warning}</li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions style={{ display: 'block', textAlign: 'right' }}>
          <Button
            onClick={() => {
              confirmFunc();
            }}
            variant="contained"
            color="primary">
            Confirm
          </Button>
          <Button
            onClick={() => {
              closeFunc();
            }}
            variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ActivationWarningPopup;
