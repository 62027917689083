import React from 'react';
import './index.css';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AdiThemeProvider from '../AdiThemeProvider';

const Footer = () => {
  return (
    <footer>
      <div className="all-footer-content">
        <div className="left-footer-content">
          <img
            className="adi-footer-logo"
            src="\images\logo.svg"
            alt="ADI"></img>

          <div className="content-below-photo">
            <h6>Contact ADI</h6>

            <br></br>

            <div className="contact-info">
              Local <a href="tel: 480-998-7200">480-998-7200</a> <br></br>
              Toll-Free <a href="tel: 1-877-592-6313 ">1-877-592-6313</a>{' '}
              <br></br> Fax <a href="tel: 480-998-7220 ">480-998-7220</a>
            </div>

            <br></br>

            <div className="location">
              6613 N Scottsdale Road <br></br> Suite 100 <br></br> Scottsdale,
              Arizona 85250
            </div>

            <br></br>

            <div className="trademark">
              ®Automotive Dealership Institute™ ADI™ and Auto Dealer Institute™
              are Registered Trademarks of Automotive Dealership Institute, Inc.
            </div>
          </div>
        </div>

        <div className="right-footer-content">
          <div className="book-icon">
            <MenuBookIcon />
          </div>

          <div className="book-message">
            <h5>Keep Up to Date - Get Email Updates</h5>
            <p> Stay tuned for the latest company news</p>
          </div>

          <div className="email-submit-form">
            <TextField
              id="filled-basic"
              label="Enter email address"
              variant="filled"
            />
            <AdiThemeProvider>
              <Button
                className="submit-button"
                variant="contained"
                color="neutral">
                Subscribe Now
              </Button>
            </AdiThemeProvider>
          </div>

          <div className="quick-links">
            <h6>Quick Links</h6>
            <div className="link-list">
              <ul>
                <li>
                  <a href="/about"> About us </a>
                </li>
                <li>
                  <a href="/about">Privacy Policy and Cookie Policy </a>
                </li>
                <li>
                  <a href="/about"> Careers </a>
                </li>
                <li>
                  <a href="/about"> Join Us </a>
                </li>
                <li>
                  <a href="/about"> Blog </a>
                </li>
                <li>
                  <a href="/about"> Help &amp; Support </a>
                </li>
                <li>
                  <a href="/about"> Contact Us </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
