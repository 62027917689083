import * as types from './actionTypes';
import * as apiConfig from '../../api/apiConfig';
import * as authApi from '../../api/authApi';

export function deviceReady(device) {
  return {
    type: types.AUTH_DEVICE_READY,
    device: apiConfig.setDefaultConfig(device)
  };
}

export function userLoggedIn(user) {
  return {
    type: types.AUTH_USER_LOGIN,
    user
  };
}

export function userLoggedOut() {
  return {
    type: types.AUTH_USER_LOGIN,
    user: null
  };
}

export function dataLoading(isLoading, loadingMessage) {
  return {
    type: types.AUTH_DATA_LOADING,
    isLoading,
    loadingMessage
  };
}

export function checkAuthToken() {
  return function(dispatch, getState) {
    return authApi.checkAuthToken().then(result => {
      dispatch(userLoggedIn(result));
      return result;
    });
  };
}

export function verifyEmail(code) {
  return function(dispatch, getState) {
    return authApi.verifyEmail(code).then(result => {
      dispatch(userLoggedIn(result));
      return result;
    });
  };
}

export function verifyText(code) {
  return function(dispatch, getState) {
    return authApi.verifyText(code).then(result => {
      dispatch(userLoggedIn(result));
      return result;
    });
  };
}

export function logInUser(email, password) {
  return function(dispatch, getState) {
    return authApi.logInUser(email, password).then(result => {
      dispatch(userLoggedIn(result));
      return result;
    });
  };
}

export function logOutUser() {
  return function(dispatch, getState) {
    return authApi.logOutUser().then(result => {
      dispatch(userLoggedOut(result));
      return result;
    });
  };
}

export function updateUser(
  email,
  phone,
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
  diplomaFirstName,
  diplomaLastName
) {
  return function(dispatch, getState) {
    return authApi
      .updateUser(
        email,
        phone,
        addressLine1,
        addressLine2,
        city,
        state,
        zip,
        diplomaFirstName,
        diplomaLastName
      )
      .then(userUpdateResult => {
        return userUpdateResult;
      });
  };
}

export function setDataLoading(isLoading, loadingMessage) {
  return function(dispatch, getState) {
    return dispatch(dataLoading(isLoading, loadingMessage));
  };
}

export function testUserLogin(t, v, tv, u) {
  return function(dispatch, getState) {
    return authApi.logInUserTest(t, v, tv, u).then(result => {
      dispatch(userLoggedIn(result));
      return result;
    });
  };
}
