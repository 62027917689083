import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function studentDataReducer(
  state = initialState.studentData,
  action
) {
  switch (action.type) {
    case types.STUDENT_DASHBOARD_LOADED:
      return { ...state, dash: { ...action.dash } };
    case types.STUDENT_CURRICULUM_LOADED:
      return {
        ...state,
        curriculum: action.curriculum
          ? [].concat(
              action.curriculum.map(section => {
                return { ...section };
              })
            )
          : null,
        userID: action.userID
      };
    default:
      return state;
  }
}
