import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../redux/actions/authActions';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import './index.css';

function CircularProgressWithLabel(props) {
  return (
    <>
      <CircularProgress
        variant="determinate"
        className="background"
        value={100}
      />
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Typography variant="caption" component="div" color="text.secondary">
            {props.progressLabel}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

function DashboardItem({ title, children, icon, progress, progressLabel }) {
  return (
    <div className="dashboard-item">
      <div className="dashboard-item-content">
        <h3>{title}</h3>
        <div className="inner-content">{children}</div>
        {icon ? <div className="icon">{icon}</div> : null}
        {progress !== undefined ? (
          <div className="progress-container">
            <CircularProgressWithLabel
              value={progress}
              progressLabel={progressLabel}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    commonData: state.commonData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardItem);
