import * as types from './actionTypes';
import * as studentDataApi from '../../api/studentDataApi';

export function studentDashboardLoaded(dash) {
  return {
    type: types.STUDENT_DASHBOARD_LOADED,
    dash
  };
}

export function loadStudentDashboard(userID) {
  return function(dispatch, getState) {
    return studentDataApi.getStudentDashboard(userID).then(result => {
      dispatch(studentDashboardLoaded(result));
      return result;
    });
  };
}

export function curriculumLoaded(curriculum, userID) {
  return {
    type: types.STUDENT_CURRICULUM_LOADED,
    curriculum,
    userID
  };
}

export function loadCurriculum(userID) {
  return function(dispatch, getState) {
    return studentDataApi.listCurriculum(userID).then(result => {
      dispatch(curriculumLoaded(result, userID));
      return result;
    });
  };
}

export function clearCurriculum() {
  return function(dispatch, getState) {
    return dispatch(curriculumLoaded(null, null));
  };
}
