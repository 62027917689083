import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { listUserActivity } from '../../api/adminDataApi';

import './index.css';

function ActivityLogPopup({ student, closeFunc }) {
  const [userActivityList, setUserActivityList] = useState(null);

  useEffect(() => {
    listUserActivity(student.UserID).then(result => {
      setUserActivityList(result);
    });
  }, []);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="activity-list-popup"
      fullScreen>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        Activity Log for {student.FirstName} {student.LastName}
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        {userActivityList ? (
          userActivityList.length ? (
            <div>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  stickyHeader>
                  <TableHead className="activity-table-head">
                    <TableRow>
                      <TableCell className="activity-header-title">
                        Activity Name
                      </TableCell>
                      <TableCell className="activity-header-title">
                        Time
                      </TableCell>
                      <TableCell className="activity-header-title">
                        Section
                      </TableCell>
                      <TableCell className="activity-header-title">
                        Chapter
                      </TableCell>
                      <TableCell className="activity-header-title">
                        Grade
                      </TableCell>

                      <TableCell className="activity-header-title">
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userActivityList.map(activity => (
                      <TableRow key={activity.DateTime}>
                        <TableCell>{activity.ActivityName}</TableCell>
                        <TableCell>
                          {activity.DateTime
                            ? moment
                                .utc(activity.DateTime)
                                .local()
                                .format('MM/DD/YYYY')
                            : null}
                        </TableCell>
                        <TableCell>{activity.SectionName}</TableCell>
                        <TableCell>{activity.SectionChapterName}</TableCell>
                        <TableCell>
                          {activity.Grade ? activity.Grade : '--'}
                        </TableCell>
                        <TableCell>
                          {activity.IsComplete ? (
                            <p className="activity-status">Completed</p>
                          ) : (
                            'In Progress'
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <div className="activity-log-empty">
              No activity has been recorded for this user.
            </div>
          )
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ActivityLogPopup;
