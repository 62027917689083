import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Transcript from '.';

import { getApiUrl, getAuthHeaders } from '../../api/apiConfig';

import * as studentDataActions from '../../redux/actions/studentDataActions';

function TranscriptPopup({ student, closeFunc, auth, actions, studentData }) {
  useEffect(() => {
    actions.loadCurriculum(student.UserID);
  }, []);

  useEffect(() => {
    console.log('studentData', studentData);
  }, [studentData]);

  function getPrintUrl() {
    const headers = getAuthHeaders();

    return (
      getApiUrl() +
      '/doc/transcript/?t=' +
      headers.t +
      '&d=' +
      headers.d +
      '&userID=' +
      student.UserID
    );
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="transcript-popup"
      fullScreen>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        Transcript for {student.FirstName} {student.LastName}
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        {studentData.curriculum && studentData.userID === student.UserID ? (
          <Transcript popUp />
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          href={getPrintUrl()}
          target="_blank"
          variant="contained"
          style={{ float: 'left' }}>
          <LocalPrintshopIcon /> &nbsp;&nbsp;Print
        </Button>

        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
    studentData: state.studentData,
    commonData: state.commonData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(studentDataActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TranscriptPopup);
