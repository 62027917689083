import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputMask from 'react-input-mask';
import Button from '@mui/material/Button';
import SchoolIcon from '@mui/icons-material/School';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  formatPhoneNumber,
  validateEmail,
  validatePassword
} from '../CommonFunctions';

import { getApiUrl, getAuthHeaders } from '../../api/apiConfig';
import { getProfileImagePath } from '../../api/authApi';
import * as authActions from '../../redux/actions/authActions';
import * as studentDataActions from '../../redux/actions/studentDataActions';

import './index.css';

function MyProfile({ auth, actions, commonData }) {
  const [contactEditMode, setContactEditMode] = React.useState(false);
  const [mailEditMode, setMailEditMode] = React.useState(
    !auth.user.AddressLine1
  );
  const [userInfo, setUserInfo] = useState({
    email: auth.user.Email,
    phone: auth.user.Phone
  });

  const [userMailingAddress, setUserMailingAddress] = useState({
    diplomaFirstName: auth.user.DiplomaFirstName,
    diplomaLastName: auth.user.DiplomaLastName,
    addressLine1: auth.user.AddressLine1,
    addressLine2: auth.user.AddressLine2,
    city: auth.user.City,
    state: auth.user.State,
    zip: auth.user.Zip
  });

  function isEditValid() {
    return (
      auth.user.Email &&
      auth.user.password &&
      validateEmail(auth.user.email) &&
      validatePassword(auth.user.password)
    );
  }

  return (
    <div className="page-container">
      <div className="page profile-page">
        <div className="doc-sign">
          {auth.user.IsDocSigned ? (
            <h4>Registration Paperwork</h4>
          ) : (
            <h4>Complete Registration</h4>
          )}
          <Button
            variant="contained"
            color="primary"
            href={
              getApiUrl() +
              'doc?name=' +
              auth.user.FirstName +
              ' ' +
              auth.user.LastName +
              '&email=' +
              auth.user.Email +
              '&isVeteran=' +
              (auth.user.IsVeteran ? 'true' : 'false')
            }>
            DocuSign <ChevronRightIcon />
          </Button>
          {auth.user.IsDocSigned ? (
            <p>
              Your registration paperwork can be accessed through your DocuSign
              account.
            </p>
          ) : (
            <p>
              Click here to continue to DocuSign to complete registration
              paperwork.
            </p>
          )}
        </div>
        <h1>
          <IconButton component={Link} to={'/'}>
            <ArrowBackIcon />
          </IconButton>
          My Profile
        </h1>
        <div className="profile-summary">
          <div
            className="user-image"
            style={{
              backgroundImage:
                auth && auth.user && auth.user.ProfileImage
                  ? "url('" + getProfileImagePath(auth.user.ProfileImage) + "')"
                  : ''
            }}>
            {auth && auth.user && !auth.user.ProfileImage ? (
              <div className="user-image-initials">
                {auth.user.FirstName.charAt(0) + auth.user.LastName.charAt(0)}
              </div>
            ) : null}

            {contactEditMode ? (
              <Dropzone
                getUploadParams={() => {
                  return {
                    url: getApiUrl() + 'FileUpload/ProfileImage',
                    headers: getAuthHeaders()
                  };
                }}
                onChangeStatus={({ meta, remove }, status) => {
                  if (status === 'headers_received') {
                    remove();
                    actions.checkAuthToken();
                    setContactEditMode(false);
                  } else if (status === 'aborted') {
                    //toast(`${meta.name}, upload failed...`);
                  }
                }}
                maxFiles={1}
                accept="image/*"
                multiple={false}
                canCancel={false}
                inputContent="Upload Photo"
              />
            ) : null}
          </div>
          <div className="about-user">
            <h2>
              {auth.user.FirstName} {auth.user.LastName}
            </h2>
            <TextField
              id="standard-basic"
              value={userInfo.email}
              type="email"
              variant="outlined"
              fullWidth={true}
              label="Email Address"
              onChange={event =>
                setUserInfo({ ...userInfo, email: event.target.value })
              }
              className="email-address"
              disabled={!contactEditMode}
            />

            {contactEditMode ? (
              <InputMask
                mask={'999-999-9999'}
                value={userInfo.phone}
                onChange={event =>
                  setUserInfo({ ...userInfo, phone: event.target.value })
                }>
                {() => (
                  <TextField
                    placeholder={'555-555-5555'}
                    variant="outlined"
                    fullWidth={true}
                    label="Cell Phone Number"
                    id="standard-basic"
                    type="phone"
                    className="phone-number"
                  />
                )}
              </InputMask>
            ) : (
              <TextField
                placeholder={'555-555-5555'}
                value={formatPhoneNumber(auth.user.Phone)}
                variant="outlined"
                fullWidth={true}
                label="Cell Phone Number"
                id="standard-basic"
                type="phone"
                className="phone-number"
                disabled
              />
            )}
          </div>
          {contactEditMode ? (
            <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                disabled={!validateEmail(userInfo.email)}
                onClick={() => {
                  actions
                    .updateUser(
                      userInfo.email,
                      userInfo.phone,
                      auth.user.AddressLine1,
                      auth.user.AddressLine2,
                      auth.user.City,
                      auth.user.State,
                      auth.user.Zip
                    )
                    .then(result => {
                      console.log('result', result);
                      if (result.IsSuccess) {
                        actions
                          .checkAuthToken()
                          .then(result => setContactEditMode(false));
                      } else {
                        //TODO: Show error
                        console.log('updateUser AppMessage', result.AppMessage);
                      }
                    });
                }}>
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setContactEditMode(false);
                }}>
                Cancel
              </Button>
            </div>
          ) : (
            <div className="buttons">
              <IconButton
                onClick={() => {
                  setContactEditMode(true);
                }}>
                <EditIcon />
              </IconButton>
            </div>
          )}
        </div>
        <div className="address-info-container">
          {mailEditMode ? (
            <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !userMailingAddress.addressLine1 ||
                  !userMailingAddress.city ||
                  !userMailingAddress.state ||
                  !userMailingAddress.zip
                }
                onClick={() => {
                  actions
                    .updateUser(
                      auth.user.Email,
                      auth.user.Phone,
                      userMailingAddress.addressLine1,
                      userMailingAddress.addressLine2,
                      userMailingAddress.city,
                      userMailingAddress.state,
                      userMailingAddress.zip,
                      userMailingAddress.diplomaFirstName,
                      userMailingAddress.diplomaLastName
                    )
                    .then(result => {
                      if (result.IsSuccess) {
                        actions
                          .checkAuthToken()
                          .then(result => setMailEditMode(false));

                        actions.loadStudentDashboard();
                      } else {
                        //TODO: Show error
                        console.log('updateUser AppMessage', result.AppMessage);
                      }
                    });
                }}>
                Save
              </Button>
              {auth.user.AddressLine1 ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setMailEditMode(false);
                  }}>
                  Cancel
                </Button>
              ) : null}
            </div>
          ) : (
            <div className="buttons">
              <IconButton
                onClick={() => {
                  setMailEditMode(true);
                }}>
                <EditIcon />
              </IconButton>
            </div>
          )}
          <div className="address-info">
            <p>
              <>
                {auth.user.AddressLine1
                  ? 'Diploma shipping address. This is where we will send your diploma:'
                  : 'Please fill out the form below so we can send you your diploma.'}
              </>
            </p>
            {mailEditMode ? (
              <div className="address-form">
                <TextField
                  id="standard-basic"
                  variant="outlined"
                  value={userMailingAddress.diplomaFirstName}
                  fullWidth={false}
                  label="First Name"
                  onChange={event =>
                    setUserMailingAddress({
                      ...userMailingAddress,
                      diplomaFirstName: event.target.value
                    })
                  }
                />

                <TextField
                  id="standard-basic"
                  variant="outlined"
                  value={userMailingAddress.diplomaLastName}
                  fullWidth={false}
                  label="Last Name"
                  onChange={event =>
                    setUserMailingAddress({
                      ...userMailingAddress,
                      diplomaLastName: event.target.value
                    })
                  }
                />

                <TextField
                  id="standard-basic"
                  variant="outlined"
                  value={userMailingAddress.addressLine1}
                  fullWidth={false}
                  label="Address Line 1"
                  onChange={event =>
                    setUserMailingAddress({
                      ...userMailingAddress,
                      addressLine1: event.target.value
                    })
                  }
                />

                <TextField
                  id="standard-basic"
                  variant="outlined"
                  fullWidth={false}
                  value={userMailingAddress.addressLine2}
                  label="Address Line 2"
                  onChange={event =>
                    setUserMailingAddress({
                      ...userMailingAddress,
                      addressLine2: event.target.value
                    })
                  }
                />

                <TextField
                  id="standard-basic"
                  variant="outlined"
                  fullWidth={false}
                  value={userMailingAddress.city}
                  label="City"
                  onChange={event =>
                    setUserMailingAddress({
                      ...userMailingAddress,
                      city: event.target.value
                    })
                  }
                />

                <TextField
                  id="outlined-select"
                  select
                  label="State"
                  value={userMailingAddress.state}
                  onChange={event =>
                    setUserMailingAddress({
                      ...userMailingAddress,
                      state: event.target.value
                    })
                  }>
                  {commonData.stateList.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="standard-basic"
                  variant="outlined"
                  fullWidth={false}
                  label="Zip Code"
                  value={userMailingAddress.zip}
                  type="number"
                  onChange={event =>
                    setUserMailingAddress({
                      ...userMailingAddress,
                      zip: event.target.value
                    })
                  }
                />
              </div>
            ) : (
              <>
                <div className="school-icon">
                  <SchoolIcon fontSize="large" />
                </div>
                <div className="mailing-address-formatted">
                  <p>
                    {auth.user.DiplomaFirstName} {auth.user.DiplomaLastName}
                  </p>
                  <p>{auth.user.AddressLine1}</p>
                  <p>
                    {auth.user.AddressLine2 ? auth.user.AddressLine2 : null}
                  </p>
                  <p>
                    {auth.user.City}, {auth.user.State}, {auth.user.Zip}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    commonData: state.commonData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(authActions, dispatch),
      ...bindActionCreators(studentDataActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
