import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import { deleteUser } from '../../api/adminDataApi';

import './index.css';

function DeleteUserPopup({ student, closeFunc }) {
  const [deleting, setDeleting] = useState(null);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="delete-user-popup">
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        Delete {student.FirstName} {student.LastName}
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <p>
          Are you sure you want to delete the user with the email address "
          {student.Email}"? Deleting cannot be undone.
        </p>
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          disabled={deleting}
          onClick={() => {
            setDeleting(true);
            deleteUser(student.UserID).then(result => closeFunc());
          }}>
          Confirm Deletion
        </Button>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteUserPopup;
