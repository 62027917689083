import React, { useEffect } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import './index.css';

function TestIcon({ test }) {
  return test.IsComplete || test.IsPassed ? (
    test.IsPassed ? (
      <div className="icon-container">
        <CheckCircleIcon sx={{ color: '#24cb27' }} />
      </div>
    ) : (
      <div className="icon-container">
        <CheckCircleIcon sx={{ color: 'red' }} />
      </div>
    )
  ) : (
    <div className="icon-container">
      <CheckCircleIcon sx={{ color: '#ccc' }} />
    </div>
  );
}

export default TestIcon;
