import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";

import { listUserNotification } from "../../api/authApi";
import { listUserNotificationAdmin } from "../../api/adminDataApi";

import * as authActions from "../../redux/actions/authActions";

import NotificationMessage from "../NotificationMessage";

import "./index.css";

function ViewAllNotifications({ auth, actions, isAdmin, userID }) {
  const [notificationList, setNotificationList] = useState(null);

  useEffect(() => {
    actions.setDataLoading(true, "Loading your notifications...");
    if (isAdmin) {
      listUserNotificationAdmin(userID).then((_notificationList) => {
        actions.setDataLoading(false);
        setNotificationList(_notificationList);
      });
    } else {
      listUserNotification(true).then((_notificationList) => {
        actions.setDataLoading(false);
        setNotificationList(_notificationList);
      });
    }
  }, []);

  return (
    <div className="page-container">
      <div className="page">
        {!isAdmin ? (
          <>
            <h1>
              <IconButton component={Link} to={"/"}>
                <ArrowBackIcon />
              </IconButton>
              All Notifications
            </h1>
          </>
        ) : null}

        {notificationList ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Notification</TableCell>
                  <TableCell align="right">Sent</TableCell>
                  <TableCell align="right">Received</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notificationList.map((notification) => (
                  <TableRow
                    key={notification.UserNotificationID}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="main-notification-container">
                        <div className="content-inside-container">
                          <div>
                            <NotificationMessage notification={notification} />
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {notification.Created
                        ? moment
                            .utc(notification.Created)
                            .local()
                            .format("M/D/YYYY h:mm:ss a")
                        : null}
                    </TableCell>
                    <TableCell align="right">
                      {notification.Viewed
                        ? moment
                            .utc(notification.Viewed)
                            .local()
                            .format("M/D/YYYY h:mm:ss a")
                        : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAllNotifications);
