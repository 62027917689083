import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { listEmail } from '../../api/adminDataApi';

import LessonIcon from '../LessonIcon';
import TestIcon from '../TestIcon';

import './index.css';

function EmailList({ auth, student, closeFunc }) {
  const [emailList, setEmailList] = useState(null);
  const [expand, setExpand] = useState({});

  useEffect(() => {
    listEmail(student.Email).then(_emailList => setEmailList(_emailList));
  }, []);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="transcript-popup"
      fullScreen>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        Emails for {student.FirstName} {student.LastName} ({student.Email})
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        {emailList ? (
          <div className="page-container email-list-page">
            <div className="page">
              <TableContainer
                sx={{
                  height: 700
                }}
                component={Paper}>
                <Table
                  sx={{
                    height: 'max-content'
                  }}
                  aria-label="simple table"
                  stickyHeader>
                  <TableHead className="table-header">
                    <TableRow>
                      <TableCell className="header-title">Subject</TableCell>
                      <TableCell
                        style={{ width: '15%' }}
                        className="header-title">
                        Sent
                      </TableCell>
                      <TableCell
                        style={{ width: '15%' }}
                        className="header-title">
                        Delivered
                      </TableCell>
                      <TableCell
                        style={{ width: '15%' }}
                        className="header-title">
                        Read
                      </TableCell>
                      <TableCell
                        style={{ width: '15%' }}
                        className="header-title">
                        Dropped
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {emailList.map(email => (
                      <>
                        <TableRow key={email.EmailID}>
                          <TableCell>
                            <Button
                              onClick={() =>
                                setExpand({
                                  ...expand,
                                  [email.EmailID]: !expand[email.EmailID]
                                })
                              }>
                              <KeyboardArrowDownIcon
                                style={{
                                  transform:
                                    expand && expand[email.EmailID]
                                      ? 'rotate(180deg)'
                                      : ''
                                }}
                              />{' '}
                              {email.Subject}
                            </Button>
                          </TableCell>
                          <TableCell>
                            {moment(email.Created).format('M/D/YYYY h:mm:ss a')}
                          </TableCell>
                          <TableCell>
                            {email.DateDelivered
                              ? moment(email.DateDelivered).format(
                                  'M/D/YYYY h:mm:ss a'
                                )
                              : null}
                          </TableCell>
                          <TableCell>
                            {email.DateRead
                              ? moment(email.DateRead).format(
                                  'M/D/YYYY h:mm:ss a'
                                )
                              : null}
                          </TableCell>
                          <TableCell>
                            {email.DateDropped
                              ? moment(email.DateDropped).format(
                                  'M/D/YYYY h:mm:ss a'
                                )
                              : null}
                            {email.DropReason
                              ? ' (' + email.DropReason + ')'
                              : ''}
                          </TableCell>
                        </TableRow>

                        {expand && expand[email.EmailID] ? (
                          <TableRow key={email.EmailID}>
                            <TableCell colSpan={7}>
                              <div className="email-preview">
                                <h4>
                                  From: <strong>{email.MailFrom}</strong>
                                </h4>
                                <h4>
                                  To: <strong>{email.MailTo}</strong>
                                </h4>
                                <div
                                  className="email-body"
                                  dangerouslySetInnerHTML={{
                                    __html: email.Body
                                  }}></div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    studentData: state.studentData,
    commonData: state.commonData
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailList);
