import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useRef, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../redux/actions/authActions';
import * as studentDataActions from '../../redux/actions/studentDataActions';
import { getProfileImagePath } from '../../api/authApi';

function AccountMenuButton(props) {
  return (
    <Button
      className="account-button"
      component={Link}
      to={props.to}
      variant="text"
      style={{ justifyContent: 'flex-start' }}
      startIcon={props.startIcon}
      onClick={props.onClick}>
      {props.children}
    </Button>
  );
}

function AccountMenu({ auth, actions }) {
  const [showAccountMenu, setShowAccountMenu] = React.useState(null);
  const keepAliveTimer = useRef();

  useEffect(() => {
    keepAliveTimer.current = setInterval(() => {
      actions.checkAuthToken();
    }, 15000);
  }, []);

  return (
    <>
      <Button
        onClick={event => {
          setShowAccountMenu(event.currentTarget);
        }}
        className="account-menu-desktop">
        <div
          className="user-image"
          style={{
            //backgroundColor: '#' + auth.user.ColorCode,
            backgroundImage:
              auth && auth.user && auth.user.ProfileImage
                ? "url('" + getProfileImagePath(auth.user.ProfileImage) + "')"
                : ''
          }}>
          {auth && auth.user && !auth.user.ProfileImage ? (
            <div className="user-image-initials">
              {auth.user.FirstName.charAt(0) + auth.user.LastName.charAt(0)}
            </div>
          ) : null}
        </div>

        <div className="user-name">
          {auth.user.FirstName} {auth.user.LastName}
        </div>
      </Button>

      <Button
        onClick={event => {
          setShowAccountMenu(event.currentTarget);
        }}
        className="account-menu-mobile">
        <MenuIcon />
      </Button>

      <Popover
        className="account-menu-popover"
        id={showAccountMenu ? 'account-menu-popover' : undefined}
        open={Boolean(showAccountMenu)}
        anchorEl={showAccountMenu}
        onClose={() => {
          setShowAccountMenu(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <div className="account-menu">
          {auth.user.UserLevelID !== 9 ? (
            <AccountMenuButton
              onClick={() => {
                setShowAccountMenu(null);
              }}
              to="/"
              startIcon={<DashboardIcon />}>
              Dashboard
            </AccountMenuButton>
          ) : null}

          {auth.user.UserLevelID !== 9 ? (
            <AccountMenuButton
              onClick={() => {
                setShowAccountMenu(null);
              }}
              to="/my-profile"
              startIcon={<AssignmentIndIcon />}>
              My Profile
            </AccountMenuButton>
          ) : null}

          {/* {auth.user.UserLevelID !== 9 ? (
            <AccountMenuButton
              onClick={() => {
                setShowAccountMenu(null);
              }}
              to="/billing-history"
              startIcon={<AttachMoneyIcon />}
            >
              Billing History
            </AccountMenuButton>
          ) : null} */}

          <AccountMenuButton
            onClick={() => {
              actions.clearCurriculum();
              actions.logOutUser();
            }}
            startIcon={<PowerSettingsNewIcon />}>
            Sign Out
          </AccountMenuButton>

          {auth.user.UserLevelID !== 9 ? (
            <Button
              className="notification-button"
              onClick={() => {
                setShowAccountMenu(null);
              }}
              component={Link}
              to="/all-notifications">
              View All Notifications
            </Button>
          ) : null}
        </div>
      </Popover>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(authActions, dispatch),
      ...bindActionCreators(studentDataActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
