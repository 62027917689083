import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { PayPalButtons } from '@paypal/react-paypal-js';
import SchoolIcon from '@mui/icons-material/School';
import DescriptionIcon from '@mui/icons-material/Description';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import CampaignIcon from '@mui/icons-material/Campaign';
import LaunchIcon from '@mui/icons-material/Launch';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, LinearProgress } from '@mui/material';

import DashBoardItem from './DashboardItem';
import AdiThemeProvider from '../AdiThemeProvider';
import { getApiUrl } from '../../api/apiConfig';
import { savePayPalPayment } from '../../api/authApi';
import * as studentDataActions from '../../redux/actions/studentDataActions';
import * as authActions from '../../redux/actions/authActions';

import { listAnnouncement } from 'api/studentDataApi';

import './index.css';

function StudentDashboard({ auth, actions, studentData }) {
  const actionsRef = useRef();
  const [announcementList, setAnnouncementList] = useState(null);
  const [jobOfferList, setJobOfferList] = useState(null);

  useEffect(() => {
    if (!studentData.dash) {
      actions.loadStudentDashboard();
    }
  }, []);

  useEffect(() => {
    loadAnnouncementList();
  }, []);

  function loadAnnouncementList() {
    listAnnouncement().then(_announcementList => {
      setAnnouncementList(
        _announcementList.filter(
          announcement => announcement.AnnouncementTypeID === 1
        )
      );
      setJobOfferList(
        _announcementList.filter(
          announcement => announcement.AnnouncementTypeID === 2
        )
      );
    });
  }

  actionsRef.current = actions;

  useEffect(() => {
    console.log('studentData', studentData);
  }, [studentData]);

  return studentData.dash ? (
    <div className="page-container student-dashboard">
      <div className="page">
        {auth.user.IsDocSigned ? (
          auth.user.IsActive ? (
            <h1>Welcome, {auth.user.FirstName}</h1>
          ) : (
            <h1>
              {auth.user.DocuSignUrl
                ? 'Enrollment Packet Sent'
                : 'Registration Complete'}
            </h1>
          )
        ) : (
          <h1>Complete Registration</h1>
        )}
        {auth.user.IsPaid ? (
          auth.user.IsDocSigned ? (
            auth.user.IsActive ? (
              <>
                <p>Are you ready for your next lesson?</p>

                <div className="dashboard-items-container">
                  <DashBoardItem
                    title={'Sections'}
                    progress={studentData.dash.SectionPercentCompleted}
                    progressLabel={
                      <>
                        {studentData.dash.SectionPercentCompleted ? (
                          <>{studentData.dash.SectionPercentCompleted}%</>
                        ) : (
                          <>0%</>
                        )}
                      </>
                    }>
                    <div>
                      <p className="about-sections">
                        {studentData.dash.SectionCountCompleted} out of{' '}
                        {studentData.dash.SectionCountTotal} <em>complete</em>
                      </p>
                    </div>
                    <br />
                    <div className="link-container">
                      {studentData.dash.DaysRemaining > 0 ? (
                        <a className="section-link" href="#/curriculum">
                          Go to current section
                        </a>
                      ) : (
                        <>Access Expired</>
                      )}
                    </div>
                  </DashBoardItem>

                  <DashBoardItem
                    title={'Transcript'}
                    icon={<DescriptionIcon />}>
                    <p className="about-transcript">
                      Take a look at your 3D Interactive Learning Platform
                      Transcript!
                    </p>
                    <div>
                      <AdiThemeProvider>
                        <Button
                          component={Link}
                          to={'transcript'}
                          variant="contained"
                          color="neutral">
                          View
                        </Button>
                      </AdiThemeProvider>
                    </div>
                  </DashBoardItem>

                  <DashBoardItem
                    title={'Time Remaining'}
                    progress={
                      studentData.dash.DaysRemaining > 90
                        ? 100
                        : (studentData.dash.DaysRemaining / 90) * 100
                    }
                    progressLabel={
                      <>
                        {studentData.dash.DaysRemaining ? (
                          <>
                            {studentData.dash.DaysRemaining} <em>days</em>
                          </>
                        ) : (
                          <>
                            0<em>days</em>
                          </>
                        )}
                      </>
                    }>
                    <p className="about-time">
                      Students receive access to their course material for up to
                      90 days.
                    </p>
                  </DashBoardItem>

                  <DashBoardItem
                    title={'Where to Send Your Diploma?'}
                    icon={<SchoolIcon />}>
                    <p>
                      Diploma shipping address. Please verify how your name
                      should appear on your diploma:
                    </p>
                    <div className="mailing-address">
                      {studentData.dash.AddressLine1 ? (
                        <>
                          <p>
                            {studentData.dash.DiplomaFirstName}{' '}
                            {studentData.dash.DiplomaLastName}
                          </p>
                          <p>{studentData.dash.AddressLine1}</p>
                          <p>
                            {studentData.dash.AddressLine2
                              ? studentData.dash.AddressLine2
                              : null}
                          </p>
                          <p>
                            {studentData.dash.City}, {studentData.dash.State},{' '}
                            {studentData.dash.Zip}
                          </p>
                        </>
                      ) : (
                        <p>
                          Click the button below to add your mailing address.
                        </p>
                      )}
                    </div>
                    <div className="mailing-button-container">
                      <AdiThemeProvider>
                        <Button
                          component={Link}
                          to={'my-profile'}
                          variant="contained"
                          color="neutral">
                          Update
                        </Button>
                      </AdiThemeProvider>
                    </div>
                  </DashBoardItem>

                  <DashBoardItem title={'Account Summary'}>
                    <p className="about-summary">
                      Thank you for trusting ADI with your career. Below is a
                      summmary of your account.
                    </p>
                    <div className="registration-information">
                      <p>
                        Registration Date:{' '}
                        {studentData.dash.DateEnrolled
                          ? moment(studentData.dash.DateEnrolled).format(
                              'MM/DD/YYYY'
                            )
                          : 'Not yet enrolled'}
                      </p>
                      <p>
                        Start Date:{' '}
                        {studentData.dash.DateStarted
                          ? moment(studentData.dash.DateStarted).format(
                              'MM/DD/YYYY'
                            )
                          : 'Not yet started'}
                      </p>
                      <p>
                        Location:{' '}
                        {studentData.dash.PromoCodeName
                          ? studentData.dash.PromoCodeName
                          : 'Not yet enrolled'}
                      </p>
                      <p>
                        Registration Fee: ${auth.user.PaymentAmount.toFixed(2)}
                      </p>
                    </div>
                    {/* <AdiThemeProvider>
                    <Button
                      variant="contained"
                      color="neutral"
                      href="https://account.docusign.com/"
                      target="_blank">
                      Download
                    </Button>
                  </AdiThemeProvider> */}
                  </DashBoardItem>

                  {/* <DashBoardItem
                  title={'F&I Digital Textbook'}
                  icon={<AutoStoriesIcon />}>
                  <p className="about-textbook">
                    Press the download button below to have instant access to
                    your digital textbook!
                  </p>
                  <div>
                    <AdiThemeProvider>
                      <Button variant="contained" color="neutral">
                        Download
                      </Button>
                    </AdiThemeProvider>
                  </div>
                </DashBoardItem> */}
                </div>

                <div className="resources-announcements-container">
                  <div className="left-side">
                    <div className="header">
                      <h1>
                        <DesktopWindowsIcon fontSize="large" />
                        Job Placement Resources
                      </h1>
                    </div>

                    <div className="line"></div>

                    <div className="announcement-side">
                      {jobOfferList ? (
                        jobOfferList.length ? (
                          jobOfferList.map(announcement => (
                            <div className="announcement">
                              <div className="announcement-content">
                                <h6>{announcement.Title}</h6>
                                <p>{announcement.Description}</p>
                              </div>
                              {announcement.Url ? (
                                <div className="launch-button">
                                  <IconButton
                                    href={announcement.Url}
                                    target="_blank">
                                    <LaunchIcon />
                                  </IconButton>
                                </div>
                              ) : null}
                            </div>
                          ))
                        ) : (
                          <div className="empty">
                            No resources have been added.
                          </div>
                        )
                      ) : (
                        <LinearProgress />
                      )}
                    </div>
                  </div>

                  <div className="right-side">
                    <div className="header">
                      <h1>
                        <CampaignIcon fontSize="large" /> Announcements
                      </h1>
                    </div>

                    <div className="line"></div>

                    <div className="announcement-side">
                      {announcementList ? (
                        announcementList.length ? (
                          announcementList.map(announcement => (
                            <div className="announcement">
                              <div className="announcement-content">
                                <h6>{announcement.Title}</h6>
                                <p>{announcement.Description}</p>
                              </div>
                              <div className="launch-button">
                                <IconButton
                                  href={announcement.Url}
                                  target="_blank">
                                  <LaunchIcon />
                                </IconButton>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="empty">
                            No announcements have been added.
                          </div>
                        )
                      ) : (
                        <LinearProgress />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="no-dash-confirmation">
                {auth.user.DocuSignUrl ? (
                  <p>
                    Thank you for completing your enrollment paperwork using
                    DocuSign, the #1 trusted eSignature platform. Your records
                    are digital sealed and secured.
                  </p>
                ) : null}
                <p>
                  You’ve completed the Automotive Dealerships Institute’s
                  registration process. Check your email inbox for how to get
                  started. We are available to answer questions at 877-998-7200.
                  Office hours are Monday through Friday 8:00am to 5:30pm MST.
                </p>

                <AdiThemeProvider>
                  <Button
                    variant="contained"
                    color="neutral"
                    onClick={() => {
                      actions
                        .logOutUser()
                        .then(
                          result =>
                            (window.location =
                              'https://autodealerinstitute.com/')
                        );
                    }}>
                    Done
                  </Button>
                </AdiThemeProvider>
              </div>
            )
          ) : (
            <div className="doc-form">
              <Alert severity="info">
                <p>
                  Click the button below to continue to DocuSign to complete
                  registration paperwork.
                </p>
              </Alert>
              <AdiThemeProvider>
                <Button
                  variant="contained"
                  color="neutral"
                  href={
                    getApiUrl() +
                    'doc?url=' +
                    encodeURIComponent(auth.user.DocuSignUrl)
                  }>
                  Continue to DocuSign <ChevronRightIcon />
                </Button>
              </AdiThemeProvider>
            </div>
          )
        ) : (
          <div className="payment-form">
            <Alert severity="info">
              <strong>
                Thank you for registering! A payment in the amount of $
                {auth.user.PaymentAmount.toFixed(2)} is required. Choose a
                payment option below to get started.
              </strong>
            </Alert>
            <div className="paypal-buttons">
              <PayPalButtons
                createOrder={(data, actions) => {
                  console.log('data', data);
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: auth.user.PaymentAmount
                        }
                      }
                    ],
                    application_context: {
                      shipping_preference: 'NO_SHIPPING'
                    }
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.order.capture().then(details => {
                    console.log('details', details);
                    if (details.status === 'COMPLETED') {
                      actionsRef.current.setDataLoading(
                        true,
                        'Saving payment information...'
                      );
                      savePayPalPayment({
                        PayPalOrderId: details.id,
                        PayPalPayerId: details.payer
                          ? details.payer.payer_id
                          : '',
                        PayPalEmail: details.payer
                          ? details.payer.email_address
                          : '',
                        PayPalFirstName:
                          details.payer && details.payer.name
                            ? details.payer.name.given_name
                            : '',
                        PayPalLastName:
                          details.payer && details.payer.name
                            ? details.payer.name.surname
                            : '',
                        Amount: details.purchase_units[0].amount.value
                      }).then(result => {
                        console.log('payment result', result);
                        actionsRef.current.checkAuthToken();
                        actionsRef.current.setDataLoading(false);
                      });
                    }
                  });
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    studentData: state.studentData,
    commonData: state.commonData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(authActions, dispatch),
      ...bindActionCreators(studentDataActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentDashboard);
