import React, { useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import Grid from '../Grid';
import GridLinkButton from '../Grid/GridLinkButton';
import TranscriptPopup from '../Transcript/TranscriptPopup';
import GridDropdownField from '../Grid/GridDropdownField';
import GridButton from '../Grid/GridButton';
import GridToggleField from '../Grid/GridToggleField';
import Button from '@mui/material/Button';

import NotificationsPopup from './NotificationsPopup';
import ProfileImage from './ProfileImage';
import AdminAnnouncements from './AdminAnnouncements';
import PromoCodes from './PromoCodes';
import GridCalendar from '../Grid/GridCalendar';
import GridEmailButton from '../Grid/GridEmailButton';

import {
  listUser,
  updateDiplomaIssueDate,
  updateUserDateExpires,
  updateUserIsActive
} from '../../api/adminDataApi';

import DeviceListPopup from './DeviceListPopup';
import ActivityLogPopup from './ActivityLogPopup';
import AdminStudentRegistrationPopup from './AdminStudentRegistrationPopup';
import DeleteUserPopup from './DeleteUserPopup';
import EmailList from '../EmailList';

import './index.css';
import JobListingPopup from './JobListingPopup';
import ActivationWarningPopup from './ActivationWarning';
import { getCookie } from 'api/apiConfig';

function Admin() {
  const gridApi = useRef();
  const columnApi = useRef();
  const [rowData, setRowData] = useState(null);
  const [showTranscript, setShowTranscript] = useState(null);
  const [showDeviceList, setShowDeviceList] = useState(null);
  const [showActivityLog, setShowActivityLog] = useState(null);
  const [
    showAdminStudentRegistrationPopup,
    setShowAdminStudentRegistrationPopup
  ] = useState(null);
  const [
    showAdminStudentAnnouncementsPopup,
    setShowAdminStudentAnnouncementPopup
  ] = useState(null);
  const [showNotificationsPopup, setShowNotificationsPopup] = useState(null);
  const [showPromoCodePopup, setShowPromoCodePopup] = useState(null);
  const [showDeleteUser, setShowDeleteUser] = useState(null);
  const [showEmailList, setShowEmailList] = useState(null);
  const [showJobListings, setShowJobListings] = useState(false);
  const [showActivationWarning, setShowActivationWarning] = useState(null);

  function onGridReady(params) {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    loadUserList();
  }

  function loadUserList() {
    setTimeout(() => {
      columnApi.current.autoSizeAllColumns();
    }, 500);

    return listUser().then(userList => setRowData(userList));
  }

  function getNumberOfDays() {
    let days = [];

    for (var i = 1; i <= 90; i++) {
      days.push(i);
    }

    return days;
  }

  function disableFunc(params) {
    if (
      params.data.IsDocRequired &&
      !params.data.DateDocSigned &&
      params.data.IsPaymentRequired &&
      !params.data.DatePaid
    ) {
      return 'Payment not completed, Documents not signed';
    } else if (params.data.IsDocRequired && !params.data.DateDocSigned) {
      return 'Documents not signed';
    } else if (params.data.IsPaymentRequired && !params.data.DatePaid) {
      return 'Payment not completed';
    }

    return null;
  }

  return (
    <div className="admin-container">
      <h1 className="admin-title">Admin</h1>
      <Grid
        columnDefs={[
          {
            field: 'ProfileImage',
            headerName: '',
            pinned: 'left',
            sortable: false,
            filter: false,
            resizable: false,
            cellRendererFramework: ProfileImage
          },
          { field: 'FirstName', headerName: 'First Name', pinned: 'left' },
          { field: 'LastName', headerName: 'Last Name', pinned: 'left' },
          { field: 'Email', headerName: 'Email' },
          { field: 'Phone', headerName: 'Phone Number' },
          { field: 'PromoCodeName', headerName: 'Promo Code' },
          {
            field: 'Created',
            headerName: 'Account Created',
            dataType: 'date'
          },
          {
            field: 'DateDocSigned',
            headerName: 'Documents Signed',
            dataType: 'date'
          },
          { field: 'DatePaid', headerName: 'Payment Date', dataType: 'date' },
          {
            field: 'DateStarted',
            headerName: 'Curriculum Start Date',
            dataType: 'date'
          },

          {
            field: 'ActiveSectionName',
            headerName: 'Transcript',
            valueGetter: params =>
              params.data.IsCourseCompleted
                ? 'Completed'
                : params.data.ActiveSectionName,
            cellRendererFramework: GridLinkButton,
            onCellClicked: function(params) {
              setShowTranscript(params.data);
            },
            cellClass: params => {
              return params.data.SectionPercentCompleted >= 100
                ? 'success'
                : '';
            }
          },
          {
            field: 'DaysRemaining',
            headerName: 'Time Remaining',
            cellClass: params => {
              return params.data.DaysRemaining < 10 ? 'warn' : '';
            }
          },
          // {
          //   field: 'DeviceCount',
          //   headerName: 'Device Count',
          //   cellRendererFramework: GridLinkButton,
          //   onCellClicked: function(params) {
          //     setShowDeviceList(params.data);
          //   }
          // },
          {
            field: 'DateCompleted',
            headerName: 'Graduation Date',
            dataType: 'date'
          },
          {
            field: 'Address',
            headerName: 'Diploma Shipping Address',
            children: [
              { field: 'DiplomaFirstName', headerName: 'First Name' },
              { field: 'DiplomaLastName', headerName: 'Last Name' },
              { field: 'AddressLine1', headerName: 'Address Line 1' },
              { field: 'City', headerName: 'City' },
              { field: 'State', headerName: 'State' },
              { field: 'Zip', headerName: 'Zip' }
            ]
          },
          {
            field: 'DateDiplomaIssued',
            headerName: 'Diploma Issue Date',
            dataType: 'date',
            cellRendererFramework: GridCalendar,
            onDataChanged: (student, field, newValue) => {
              return updateDiplomaIssueDate(student.UserID, newValue).then(
                result => {
                  loadUserList();
                  return result;
                }
              );
            }
          },
          {
            field: 'EmailCount',
            headerName: 'Emails',
            suppressMenu: true,
            suppressSorting: true,
            pinned: 'right',
            cellRendererFramework: GridEmailButton,
            onCellClicked: function(params) {
              setShowEmailList(params.data);
            }
          },
          {
            field: 'UserNotifications',
            headerName: 'Notifications',
            pinned: 'right',
            cellRendererFramework: GridButton,
            onCellClicked: function(params) {
              setShowNotificationsPopup(params.data);
            },
            valueGetter: function(params) {
              return 'Notifications';
            }
          },
          {
            field: 'DaysExtended',
            headerName: 'Extend Access',
            pinned: 'right',
            cellRendererFramework: GridDropdownField,
            optionListFunc: getNumberOfDays,
            numbersOnly: true,
            onDataChanged: (student, field, newValue) => {
              return updateUserDateExpires(student.UserID, newValue).then(
                result => {
                  loadUserList();
                  return result;
                }
              );
            }
          },
          {
            field: 'ActivityLog',
            headerName: 'Activity Log',
            pinned: 'right',
            cellRendererFramework: GridButton,
            onCellClicked: function(params) {
              setShowActivityLog(params.data);
            },
            valueGetter: function(params) {
              return 'Activity Log';
            }
          },

          {
            field: 'TestUser',
            headerName: 'Test User',
            pinned: 'right',
            cellRendererFramework: GridButton,
            valueGetter: function(params) {
              return (
                <a
                  style={{
                    position: 'absolute',
                    top: '0px',
                    bottom: '0px',
                    left: '0px',
                    right: '0px',
                    margin: 'auto',
                    padding: '8px',
                    color: '#ffffff',
                    textDecoration: 'none'
                  }}
                  href={
                    window.location.href +
                    'test-user?t=' +
                    getCookie('adi-t') +
                    '&v=' +
                    getCookie('adi-visitor') +
                    '&u=' +
                    params.data.UserID
                  }
                  onClick={event => {
                    alert(
                      'Right click the Test User button and select "Open link in incognito window"'
                    );
                    event.preventDefault();
                  }}>
                  Test User
                </a>
              );
            }
          },

          {
            field: 'IsActive',
            headerName: 'Status',
            pinned: 'right',
            valueGetter: params => {
              return (
                (!params.data.IsDocRequired || params.data.DateDocSigned) &&
                (!params.data.IsPaymentRequired || params.data.DatePaid) &&
                params.data.IsActive
              );
            },
            tooltipFunc: disableFunc,
            cellRendererFramework: GridToggleField,
            onDataChanged: (student, field, newValue) => {
              let warningList = [];

              if (student.IsPaymentRequired && !student.DatePaid) {
                warningList.push('Payment has not been received');
              }

              if (student.IsDocRequired && !student.DateDocSigned) {
                warningList.push('Documents have not been signed');
              }

              if (warningList.length && newValue) {
                setShowActivationWarning({ ...student, warningList });
              } else {
                return updateUserIsActive(student.UserID, newValue).then(
                  result => {
                    loadUserList();
                    return result;
                  }
                );
              }
            }
          },
          {
            field: 'Delete',
            headerName: 'Delete',
            cellRendererFramework: GridButton,
            onCellClicked: function(params) {
              setShowDeleteUser(params.data);
            },
            valueGetter: function(params) {
              return 'Delete';
            },
            width: 100
          }
        ]}
        rowData={rowData}
        onGridReady={onGridReady}
        immutableData={true}
        getRowNodeId={data => data.UserID}
        getRowClass={params => {
          return disableFunc(params) ? 'disabled' : '';
        }}
        refreshFunc={loadUserList}>
        <div className="param-group">
          <Button
            variant="contained"
            onClick={() => {
              setShowAdminStudentRegistrationPopup(true);
            }}>
            + Add User
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setShowPromoCodePopup(true);
            }}>
            Promo Codes
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setShowAdminStudentAnnouncementPopup(true);
            }}>
            User Announcements
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setShowJobListings(true);
            }}>
            Job Listings
          </Button>
        </div>
      </Grid>
      {showTranscript ? (
        <TranscriptPopup
          closeFunc={() => setShowTranscript(null)}
          student={showTranscript}
        />
      ) : null}

      {showDeviceList ? (
        <DeviceListPopup
          closeFunc={() => setShowDeviceList(null)}
          student={showDeviceList}
        />
      ) : null}

      {showActivityLog ? (
        <ActivityLogPopup
          closeFunc={() => setShowActivityLog(null)}
          student={showActivityLog}
        />
      ) : null}

      {showAdminStudentRegistrationPopup ? (
        <AdminStudentRegistrationPopup
          closeFunc={() => setShowAdminStudentRegistrationPopup(null)}
          student={showAdminStudentRegistrationPopup}
          onUserAdded={() => {
            setShowAdminStudentRegistrationPopup(null);
            loadUserList();
          }}
        />
      ) : null}

      {showAdminStudentAnnouncementsPopup ? (
        <AdminAnnouncements
          closeFunc={() => setShowAdminStudentAnnouncementPopup(null)}
          student={showAdminStudentAnnouncementsPopup}
        />
      ) : null}

      {showNotificationsPopup ? (
        <NotificationsPopup
          closeFunc={() => setShowNotificationsPopup(null)}
          student={showNotificationsPopup}
        />
      ) : null}

      {showPromoCodePopup ? (
        <PromoCodes closeFunc={() => setShowPromoCodePopup(null)} />
      ) : null}

      {showActivityLog ? (
        <ActivityLogPopup
          closeFunc={() => setShowActivityLog(null)}
          student={showActivityLog}
        />
      ) : null}

      {showDeleteUser ? (
        <DeleteUserPopup
          closeFunc={() => {
            setShowDeleteUser(null);
            loadUserList();
          }}
          student={showDeleteUser}
        />
      ) : null}

      {showEmailList ? (
        <EmailList
          closeFunc={() => {
            setShowEmailList(null);
          }}
          student={showEmailList}
        />
      ) : null}

      {showJobListings ? (
        <JobListingPopup
          closeFunc={() => {
            setShowJobListings(null);
          }}
        />
      ) : null}

      {showActivationWarning ? (
        <ActivationWarningPopup
          closeFunc={() => {
            window.location.reload();
          }}
          confirmFunc={() => {
            updateUserIsActive(
              showActivationWarning.UserID,
              true
            ).then(result => window.location.reload());
          }}
          student={showActivationWarning}
        />
      ) : null}
    </div>
  );
}

export default Admin;
