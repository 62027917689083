import axios from 'axios';

axios.defaults.baseURL = getApiUrl();

let defaultConfig = null;

export function getApiUrl() {
  return window.location.href.indexOf('localhost') !== -1
    ? 'https://localhost:44330/'
    : '/adi-api/';
}

export function setDefaultConfig(device) {
  let headers = {
    d: device.deviceUniqueId,
    y: device.isMobile ? 'Mobile' : device.isTablet ? 'Tablet' : 'Desktop',
    b:
      (device.osName ? device.osName : '') +
      (device.osVersion ? ' ' + device.osVersion : '') +
      (device.browserName ? ' ' + device.browserName : '') +
      (device.engineVersion ? ' ' + device.engineVersion : '')
  };

  if (getAuthTokenCookie()) {
    device.hasAuthToken = true;
  }

  defaultConfig = {
    headers: headers
  };

  return { ...device };
}

export function getAuthHeaders() {
  return defaultConfig ? defaultConfig.headers : {};
}

export function setAuthToken(token) {
  setCookie('adi-t', token);
}

export function getAuthTokenCookie() {
  return getCookie('adi-t');
}

function getConfigObject(appendToDefaultConfig) {
  let _config = { ...defaultConfig };

  const token = getAuthTokenCookie();
  if (_config && token) {
    _config.headers.t = token;
  }

  if (appendToDefaultConfig) {
    if (appendToDefaultConfig.headers) {
      _config.headers = {
        ..._config.headers,
        ...appendToDefaultConfig.headers
      };
    }
  }

  return _config;
}

export function get(url, config) {
  return axios
    .get(url, url.indexOf('http') === -1 ? getConfigObject(config) : config)
    .then(function(response) {
      return response;
    })
    .catch(handleError);
}

export function post(url, data, config) {
  return axios
    .post(
      url,
      data,
      url.indexOf('http') === -1 ? getConfigObject(config) : null
    )
    .then(function(response) {
      return response;
    })
    .catch(handleError);
}

export function handleError(error) {
  // eslint-disable-next-line no-console

  throw error;
}

export function getCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

export function setCookie(name, value, hours) {
  var d = new Date();
  if (hours) {
    var time = d.getTime();
    time += 3600 * 1000;
    d.setTime(time);
  } else {
    d.setFullYear(d.getFullYear() + 1);
  }
  document.cookie = name + '=' + value + ';path=/;expires=' + d.toUTCString();
}

export function getSessionStorage(name) {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    return window.sessionStorage.getItem(name);
  }

  return '';
}

export function setSessionStorage(name, value) {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    window.sessionStorage.setItem(name, value);
  }
}
