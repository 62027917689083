import React, { useEffect, useState } from 'react';
import './index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import AdiThemeProvider from '../AdiThemeProvider';
import ReactCodeInput from 'react-code-input';
import InputMask from 'react-input-mask';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

import * as authActions from '../../redux/actions/authActions';
import {
  registerUser,
  getEmailVerificationCode,
  getTextVerificationCode,
  requestPasswordReset,
  resetPassword
} from '../../api/authApi';
import {
  scrollToTop,
  validateEmail,
  validatePassword
} from '../CommonFunctions';
import RegistrationForm from '../RegistrationForm';

const LoginForm = ({ auth, actions }) => {
  const [activeStep, setActiveStep] = useState(null);
  const [newUser, setNewUser] = useState({});
  const [user, setUser] = useState({});
  const [emailVerify, setEmailVerify] = useState({});
  const [textVerify, setTextVerify] = useState({});
  const [codeFail, setCodeFail] = useState({});
  const [showEmailSent, setShowEmailSent] = useState(false);
  const [invalidPasswordResetCode, setInvalidPasswordResetCode] = useState(
    false
  );
  const [activationMethod, setActivationMethod] = useState(null);

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    if (textVerify.code && String(textVerify.code).length === 6)
      submitTextCode();
  }, [textVerify]);

  function submitTextCode() {
    setCodeFail({ ...codeFail, text: false });
    actions.verifyText(textVerify.code).then(result => {
      if (!result.EmailVerificationRequired) {
        setCodeFail({ ...codeFail, text: true });
      }
      console.log('verifyText result', result);
    });
  }

  useEffect(() => {
    if (emailVerify.code && String(emailVerify.code).length === 6)
      submitEmailCode();
  }, [emailVerify]);

  function submitEmailCode() {
    setCodeFail({ ...codeFail, email: false });
    actions.verifyEmail(emailVerify.code).then(result => {
      if (!result.EmailVerificationRequired) {
        setCodeFail({ ...codeFail, email: true });
      }
      console.log('verifyEmail result', result);
    });
  }

  useEffect(() => {
    console.log('auth', auth);
    if (auth && auth.token && !auth.user) {
      //Try to log the user in based on their token
      actions.checkAuthToken();
    }

    if (auth && !auth.user) {
      actions.checkAuthToken().then(result => {
        console.log('auth token result', result);
      });
    }

    if (auth) {
      if (auth.user && auth.user.IsUser) {
        if (
          (auth.user.EmailVerificationRequired ||
            auth.user.TextVerificationRequired) &&
          !activationMethod
        ) {
          setActiveStep('Activation');
        } else if (
          auth.user.EmailVerificationRequired &&
          activationMethod === 'Email'
        ) {
          setActiveStep('Email');
        } else if (
          auth.user.TextVerificationRequired &&
          activationMethod === 'Text'
        ) {
          setActiveStep('Text');
        } else {
          setActiveStep('Login');
        }
      } else if (!activeStep) {
        if (auth.device.hasAuthToken) {
          setActiveStep('Login');
        } else {
          setActiveStep('Register');
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    scrollToTop();
  }, [activeStep]);

  function doLogin(email, password) {
    return actions.logInUser(email, password).then(result => {
      console.log('result in app', result);
      return result;
    });
  }

  function isLoginValid() {
    return (
      user.email &&
      user.password &&
      validateEmail(user.email) &&
      validatePassword(user.password)
    );
  }

  return (
    <div className="page-container login-page">
      <div className="photo-side">
        <div className="adi-main-logo-container">
          <img
            className="adi-main-logo"
            src="/images/logo-icon.svg"
            alt="adi-logo"></img>
        </div>
      </div>
      <div className="form-side">
        <div className={'form' + (activeStep === 'Register' ? ' active' : '')}>
          <RegistrationForm
            activeStep={activeStep}
            doLogin={doLogin}
            setActiveStep={setActiveStep}
          />
        </div>

        <div className={'form' + (activeStep === 'Login' ? ' active' : '')}>
          <h1>Welcome Back!</h1>

          <p className="sign-in-para">Please use the form below to sign in</p>

          {user.error ? <p className="error">{user.error}</p> : null}
          <form
            onSubmit={event => {
              console.log('On Submit Called');
              actions.setDataLoading(true, 'Logging in...');
              if (isLoginValid()) {
                setUser({ ...user, error: '' });
                doLogin(user.email, user.password).then(result => {
                  actions.setDataLoading(false);
                  if (!result.IsUser) {
                    actions.setDataLoading(false);
                    setUser({
                      ...user,
                      error: 'Invalid email or password. Please try again.'
                    });
                  }
                });
              }

              event.stopPropagation();
              event.preventDefault();
            }}>
            <div className="sign-in-form-container">
              <TextField
                id="standard-basic"
                label="Enter Email"
                type="email"
                variant="standard"
                className="text-field"
                value={user.email}
                onChange={event =>
                  setUser({
                    ...user,
                    email: event.target.value
                  })
                }
              />

              <TextField
                id="standard-password-input"
                label="Enter Password"
                type={values.showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                variant="standard"
                className="text-field"
                value={user.password}
                onChange={event =>
                  setUser({
                    ...user,
                    password: event.target.value
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>

            <AdiThemeProvider>
              <Button
                className="sign-in-button"
                variant="contained"
                color="neutral"
                type="submit"
                disabled={!isLoginValid()}>
                Sign In
              </Button>
            </AdiThemeProvider>
          </form>

          <p className="need-account">
            {"Don't have an account?"}{' '}
            <a
              onClick={event => {
                setActiveStep('Register');
              }}>
              Register here.
            </a>
          </p>
          <p className="forgot-password">
            <a
              onClick={event => {
                setActiveStep('PasswordReset');
              }}>
              Forgot password?
            </a>
          </p>
        </div>

        <div
          className={
            'form' + (activeStep === 'PasswordReset' ? ' active' : '')
          }>
          <>
            <p>
              Please enter your email so we can help you reset your password.
            </p>
            <TextField
              id="standard-basic"
              type="email"
              variant="standard"
              className="text-field"
              value={user.email}
              onChange={event =>
                setUser({ ...user, email: event.target.value })
              }
            />

            <AdiThemeProvider>
              <Button
                className="reset-password-button"
                variant="contained"
                color="neutral"
                type="submit"
                onClick={() => {
                  actions.setDataLoading(true, 'Requesting...');
                  requestPasswordReset(user.email).then(result => {
                    setShowEmailSent(true);
                    setActiveStep('ResetVerify');
                    actions.setDataLoading(false);
                  });
                }}>
                Submit
              </Button>
            </AdiThemeProvider>
          </>
        </div>

        <div
          className={'form' + (activeStep === 'ResetVerify' ? ' active' : '')}>
          <>
            <p>Please enter the code we sent to your email.</p>
            <div className="code-input-container">
              <ReactCodeInput
                type="number"
                fields={6}
                value={emailVerify.code}
                onChange={value =>
                  setEmailVerify({
                    ...emailVerify,
                    code: value
                  })
                }
              />
            </div>

            <AdiThemeProvider>
              <Button
                className="submit-reset-button"
                variant="contained"
                color="neutral"
                type="submit"
                onClick={() => {
                  setActiveStep('NewPassword');
                }}>
                Submit
              </Button>
            </AdiThemeProvider>

            {invalidPasswordResetCode ? (
              <p className="error-message" style={{ marginTop: '10px' }}>
                Invalid code, please try again.
              </p>
            ) : showEmailSent ? (
              <p className="email-sent-message">
                We've sent you an email with further instructions on how to
                reset your password.
              </p>
            ) : null}
          </>
        </div>

        <div
          className={'form' + (activeStep === 'NewPassword' ? ' active' : '')}>
          <>
            <p>Please enter a new password.</p>
            <TextField
              id="standard-password-input"
              type={values.showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              variant="standard"
              className="text-field"
              value={user.password}
              onChange={event =>
                setUser({
                  ...user,
                  password: event.target.value
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <AdiThemeProvider>
              <Button
                className="reset-password-button"
                variant="contained"
                color="neutral"
                type="submit"
                disabled={!validatePassword(user.password)}
                onClick={() => {
                  setInvalidPasswordResetCode(false);
                  actions.setDataLoading(true, 'Saving your password...');
                  resetPassword(
                    user.email,
                    emailVerify.code,
                    user.password
                  ).then(result => {
                    actions.setDataLoading(false);
                    if (result === 'SUCCESS') {
                      doLogin(user.email, user.password);
                    } else {
                      setEmailVerify({
                        ...emailVerify,
                        code: null
                      });
                      setInvalidPasswordResetCode(true);
                      setActiveStep('ResetVerify');
                    }
                  });
                }}>
                Change Password
              </Button>
            </AdiThemeProvider>
          </>
        </div>

        <div
          className={'form' + (activeStep === 'Activation' ? ' active' : '')}>
          <h1>Device Activation</h1>

          <p>
            To continue logging in, you must activate this device. We'll send a
            code to either your email address or the phone number on file.
          </p>

          <p>Select which method you'd like to use to activate this device.</p>

          {auth && auth.user ? (
            <div className="activation-method-select">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(event, newValue) => setActivationMethod(newValue)}>
                  <FormControlLabel
                    fullWidth
                    value={'Email'}
                    control={<Radio />}
                    label={'Send email to ' + auth.user.Email}
                  />
                  <FormControlLabel
                    fullWidth
                    value={'Text'}
                    control={<Radio />}
                    label={'Send text message to ' + auth.user.Phone}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          ) : null}

          <AdiThemeProvider>
            <Button
              className="submit-code-button"
              variant="contained"
              color="neutral"
              disabled={!activationMethod}
              onClick={event => {
                actions.setDataLoading(true, 'Requesting code...');
                if (activationMethod === 'Email') {
                  getEmailVerificationCode().then(result => {
                    actions.setDataLoading(false);
                    setActiveStep('Email');
                  });
                } else {
                  getTextVerificationCode().then(result => {
                    actions.setDataLoading(false);
                    setActiveStep('Email');
                  });
                }
              }}>
              Continue
            </Button>
          </AdiThemeProvider>
        </div>

        <div className={'form' + (activeStep === 'Email' ? ' active' : '')}>
          <h1>Email Verification</h1>

          <p className="email-verify-para">
            We sent a code to{' '}
            {auth && auth.user && auth.user.Email
              ? auth.user.Email
              : ' the email address we have on file'}
            . Please use the form below to verify.
          </p>

          <div className="code-input-container">
            <ReactCodeInput
              type="number"
              fields={6}
              value={emailVerify.code}
              onChange={value =>
                setEmailVerify({
                  ...emailVerify,
                  code: value
                })
              }
            />
          </div>

          {codeFail.email ? (
            <p className="error-message">Invalid code, please try again.</p>
          ) : null}

          <AdiThemeProvider>
            <Button
              className="submit-code-button"
              variant="contained"
              color="neutral"
              disabled={
                !emailVerify.code || String(emailVerify.code).length !== 6
              }
              onClick={event => {
                actions.verifyEmail(emailVerify.code).then(result => {
                  console.log('verifyEmail result', result);
                });
              }}>
              Submit
            </Button>
          </AdiThemeProvider>

          <p className="need-account">
            {"Didn't receive a code?"}{' '}
            <a
              onClick={event => {
                setEmailVerify({ ...emailVerify, error: '' });
                getEmailVerificationCode().then(result => {
                  console.log('getEmailVerificationCode', result);
                  setEmailVerify({
                    ...emailVerify,
                    error: result.AppMessage,
                    success: result.IsSuccess
                  });
                });
              }}>
              Click here
            </a>{' '}
            {'to resend.'}
          </p>

          {emailVerify.error ? (
            <p className="error">{emailVerify.error}</p>
          ) : emailVerify.success ? (
            <p className="success">Code requested successfully</p>
          ) : null}
        </div>

        <div className={'form' + (activeStep === 'Text' ? ' active' : '')}>
          <h1>Text Verification</h1>

          <p className="text-verify-para">
            We sent a code to{' '}
            {auth && auth.user && auth.user.Phone
              ? auth.user.Phone
              : ' the phone number we have on file'}
            .. Please use the form below to verify
          </p>

          <div className="code-input-container">
            <ReactCodeInput
              type="number"
              fields={6}
              value={textVerify.code}
              onChange={value =>
                setTextVerify({
                  ...textVerify,
                  code: value
                })
              }
            />
          </div>

          {codeFail.text ? (
            <p className="error-message">Invalid code, please try again.</p>
          ) : null}

          <AdiThemeProvider>
            <Button
              className="submit-code-button"
              variant="contained"
              color="neutral"
              disabled={
                !textVerify.code || String(textVerify.code).length !== 6
              }
              onClick={event => {
                actions.verifyText(textVerify.code).then(result => {
                  console.log('verifyText result', result);
                });
              }}>
              Submit
            </Button>
          </AdiThemeProvider>

          <p className="need-account">
            {"Didn't receive a code?"}{' '}
            <a
              onClick={event => {
                setTextVerify({ ...textVerify, error: '' });
                getTextVerificationCode().then(result => {
                  console.log('getTextVerificationCode', result);
                  setTextVerify({
                    ...textVerify,
                    error: result.AppMessage,
                    success: result.IsSuccess
                  });
                });
              }}>
              Click here
            </a>{' '}
            {'to resend.'}
          </p>

          {textVerify.error ? (
            <p className="error">{textVerify.error}</p>
          ) : textVerify.success ? (
            <p className="success">Code requested successfully</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
