import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { Menu, MenuItem, Button, Divider } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import CachedIcon from '@mui/icons-material/Cached';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Popover
} from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './Grid.css';
import GetAppIcon from '@mui/icons-material/GetApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

const $ = window.$;

function Grid(props) {
  const gridApi = useRef();
  const columnApi = useRef();
  const [prefsApplied, setPrefsApplied] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [columnSetList, setColumnSetList] = useState(
    props.columnSets ? props.columnSets : []
  );
  const [columnSetMenuOpen, setColumnSetMenuOpen] = useState(null);
  const [selectedColumnSet, setSelectedColumnSet] = useState(null);
  const [gridCustomized, setGridCustomized] = useState(false);
  const [promptSaveColumnSet, setPromptSaveColumnSet] = useState(false);
  const [columnSetSaveOption, setColumnSetSaveOption] = useState('overwrite');
  const [newColumnSetTitle, setNewColumnSetTitle] = useState('');
  const [newColumnSetError, setNewColumnSetError] = useState(null);
  const [visibleRowCount, setVisibleRowCount] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [searchString, setSearchString] = useState(null);
  const [columnJumpOptions, setColumnJumpOptions] = useState([]);
  const [columnJumpInputValue, setColumnJumpInputValue] = useState(null);
  const [gridExportInProgress, setGridExportInProgress] = useState(false);
  const searchTimeout = useRef();
  const [serverSideFieldListVisible, setServerSideFieldListVisible] = useState(
    null
  );

  const _defaultColDef = {
    enableCellChangeFlash: true,
    sortable: true,
    filter: true,
    resizable: true
  };

  const defaultColDef = props.defaultColDef
    ? { ...props.defaultColDef, ..._defaultColDef }
    : _defaultColDef;

  useEffect(() => {
    var arrColDef = [];

    if (props.columnDefs) {
      props.columnDefs.map(curColDef => {
        let colDef = handleColDefDataType(curColDef);

        if (colDef.children) {
          colDef.children = colDef.children.map(childColDef =>
            handleColDefDataType(childColDef)
          );
        }

        if (props.trackAs && !colDef.colId) {
          colDef.colId = props.trackAs + '_' + colDef.field;
        }

        arrColDef.push(colDef);
      });
    }

    function handleColDefDataType(_colDef) {
      let colDef = { ..._colDef };

      switch (colDef.dataType) {
        case 'dateTime': {
          if (!colDef.valueFormatter) {
            colDef.valueFormatter = function(params) {
              return params.value
                ? moment
                    .utc(params.value)
                    .local()
                    .format('M/D/YYYY h:mm:ss a')
                : '';
            };
          }

          colDef.filter = 'agDateColumnFilter';
          break;
        }
        case 'date': {
          if (!colDef.valueFormatter) {
            colDef.valueFormatter = function(params) {
              return params.value
                ? moment(params.value).format('M/D/YYYY')
                : '';
            };
          }

          colDef.filter = 'agDateColumnFilter';

          break;
        }
        case 'dollars': {
          colDef.valueGetter = function(params) {
            let value =
              params.data &&
              params.data[colDef.field] !== null &&
              params.data[colDef.field] !== undefined &&
              params.data[colDef.field] !== ''
                ? Number(params.data[colDef.field])
                : '';

            return value;
          };

          colDef.valueFormatter = function(params) {
            return params.value && !isNaN(params.value)
              ? '$' +
                  parseFloat(params.value).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
              : '';
          };

          colDef.cellClass = 'right dollars';

          colDef.filter = 'agNumberColumnFilter';

          break;
        }
        case 'bool': {
          colDef.valueFormatter = function(params) {
            return params.value !== null && params.value !== undefined
              ? params.value && params.value != 'False'
                ? 'Yes'
                : ''
              : '';
          };
          break;
        }
        case 'numeric':
        case 'integer': {
          if (!colDef.cellRendererFramework) {
            colDef.cellClass = 'right';
          }

          colDef.valueGetter = function(params) {
            let value =
              params.data &&
              params.data[colDef.field] !== null &&
              params.data[colDef.field] !== undefined &&
              params.data[colDef.field] !== ''
                ? Number(params.data[colDef.field])
                : '';

            if (value && colDef.dataType == 'integer') {
              if (!isNaN(value)) {
                value = parseInt(value);
              }
            }

            return value;
          };

          colDef.valueFormatter = function(params) {
            return params.value && !isNaN(params.value)
              ? parseFloat(params.value).toLocaleString()
              : '';
          };

          colDef.filter = 'agNumberColumnFilter';

          break;
        }
        case 'alphanumeric': {
          colDef.valueGetter = function(params) {
            let value =
              params.data &&
              params.data[colDef.field] !== null &&
              params.data[colDef.field] !== undefined &&
              params.data[colDef.field] !== ''
                ? params.data[colDef.field]
                : '';

            if (value) {
              if (!isNaN(value)) {
                value = parseFloat(value);
              }
            }

            return value;
          };

          break;
        }
        default:
          //do nothing
          break;
      }

      return colDef;
    }

    setColumnDefs(arrColDef);

    var arrColJumpOption = [];

    arrColDef.map(curColDef => {
      if (curColDef.children) {
        arrColJumpOption.push({
          headerName: curColDef.headerName,
          field: curColDef.children[0].field
        });

        curColDef.children.map(curChildColDef => {
          arrColJumpOption.push({
            headerName: curChildColDef.headerName,
            field: curChildColDef.field
          });
        });
      } else {
        arrColJumpOption.push({
          headerName: curColDef.headerName,
          field: curColDef.field
        });
      }
    });

    setColumnJumpOptions(arrColJumpOption);

    preventPinningOnMobile();
  }, [props.columnDefs]);

  useEffect(() => {
    if (gridApi.current) {
      setVisibleRowCount(gridApi.current.getDisplayedRowCount());
      updateTotalRowCount();
    }
  }, [props.rowData, gridApi.current]);

  useEffect(() => {
    if (getRawPrefs()) {
      if (gridApi.current) {
        initGridPrefs();
      }
    }
  }, [getRawPrefs()]);

  useEffect(() => {
    if (columnSetList.length) {
      if (newColumnSetTitle) {
        setColumnSet(newColumnSetTitle);
        setColumnSetSaveOption('overwrite');
        setNewColumnSetTitle('');
      } else {
        setColumnSet(columnSetList[0].title);
      }
    }
  }, [columnSetList.length]);

  useEffect(() => {}, [selectedColumnSet]);

  useEffect(() => {
    if (prefsApplied) {
      initGridPrefs(true);
    }
  }, [props.authUser]);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, []);

  function isMobile() {
    return window.screen && window.screen.width <= 667;
  }

  function getRawPrefs() {
    return [];
  }

  function initGridPrefs(force) {
    if (!prefsApplied || force) {
      const rawPrefList = getRawPrefs();

      let arrColSet = [];

      if (rawPrefList) {
        let dictColSet = {};
        rawPrefList.map(curPref => {
          let title = curPref.ObjectID.split(props.trackAs)[1];
          if (title) {
            if (!dictColSet[title]) {
              dictColSet[title] = { title };
            }

            let colSet = { ...dictColSet[title] };
            colSet[curPref.Name] = JSON.parse(curPref.Value);

            dictColSet[title] = colSet;
          }
        });

        for (var curKey in dictColSet) {
          if (curKey === 'Default') {
            arrColSet.splice(0, 0, dictColSet[curKey]);
          } else {
            arrColSet.push(dictColSet[curKey]);
          }
        }
      }

      if (
        !arrColSet.length &&
        !columnSetList.filter(colSet => colSet.title === 'Default').length
      ) {
        arrColSet.push({ title: 'Default' });
      }

      setColumnSetList(arrColSet.concat(columnSetList));
    }
  }

  function preventPinningOnMobile() {
    if (columnApi.current && isMobile()) {
      columnApi.current
        .getColumnState()
        .map(curCol => columnApi.current.setColumnPinned(curCol.colId));
    }
  }

  function setColumnSet(columnSetTitle) {
    if (columnApi.current) {
      var colSet = columnSetList.filter(
        colSet => colSet.title === columnSetTitle
      )[0];

      setSelectedColumnSet(colSet);
      handleColumnSetMenuButtonClosed();

      if (colSet.columnState) {
        columnApi.current.setColumnState(colSet.columnState);
      } else if (
        colSet.title === 'Default' &&
        columnApi.current.defaultColumnState &&
        columnSetList.length > 1
      ) {
        columnApi.current.setColumnState(columnApi.current.defaultColumnState);
      }

      if (colSet.filterModel) {
        gridApi.current.setFilterModel(colSet.filterModel);
      }

      if (colSet.sortModel) {
        gridApi.current.setSortModel(colSet.sortModel);
      }

      preventPinningOnMobile();

      setGridCustomized(false);
      setPrefsApplied(false);
      setTimeout(() => {
        setPrefsApplied(true);
      }, 200);
    }
  }

  const handleColumnSetMenuButtonClick = event => {
    setColumnSetMenuOpen(event.currentTarget);
  };

  const handleColumnSetMenuButtonClosed = () => {
    setColumnSetMenuOpen(null);
  };

  function onGridReady(params) {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;

    if (getRawPrefs()) {
      initGridPrefs();
    }

    if (props.onGridReady) {
      props.onGridReady(params);
    }
  }

  function onColumnMoved(params) {
    if (prefsApplied) {
      setGridCustomized(true);
    }

    if (props.onColumnMoved) {
      props.onColumnMoved(params);
    }
  }

  function onColumnResized(params) {
    if (prefsApplied) {
      setGridCustomized(true);
    }

    if (props.onColumnResized) {
      props.onColumnResized(params);
    }
  }

  function onColumnPinned(params) {
    if (prefsApplied) {
      setGridCustomized(true);
    }

    if (props.onColumnPinned) {
      props.onColumnPinned(params);
    }
  }

  function onColumnVisible(params) {
    if (prefsApplied) {
      setGridCustomized(true);
    }

    if (props.onColumnVisible) {
      props.onColumnVisible(params);
    }
  }

  function onFilterChanged(params) {
    if (prefsApplied) {
      setGridCustomized(true);
    }

    setVisibleRowCount(gridApi.current.getDisplayedRowCount());
    updateTotalRowCount();

    if (props.onFilterChanged) {
      props.onFilterChanged(params);
    }
  }

  function updateTotalRowCount() {
    let _totalRowCount = 0;

    gridApi.current.forEachNode(node => {
      if (node.level === 0) {
        _totalRowCount++;
      }
    });

    setTotalRowCount(_totalRowCount);
  }

  function onSortChanged(params) {
    if (prefsApplied) {
      setGridCustomized(true);
    }

    if (props.onSortChanged) {
      props.onSortChanged(params);
    }
  }

  function confirmSaveColumnSet() {
    var exists = columnSetList.filter(
      curColumnSet =>
        curColumnSet.title.toUpperCase() === newColumnSetTitle.toUpperCase()
    ).length;

    if (exists && columnSetSaveOption === 'new') {
      setNewColumnSetError('Column set already exists with that title');
    } else {
      setNewColumnSetError(null);

      const preferenceKey =
        props.trackAs +
        (columnSetSaveOption === 'new'
          ? newColumnSetTitle
          : selectedColumnSet.title);

      const rawPreferenceList = [
        {
          URL: null,
          ObjectID: preferenceKey,
          Type: 'json',
          Name: 'columnSet',
          Value: JSON.stringify({ name: newColumnSetTitle })
        },
        {
          URL: null,
          ObjectID: preferenceKey,
          Type: 'json',
          Name: 'columnState',
          Value: JSON.stringify(columnApi.current.getColumnState())
        },
        {
          URL: null,
          ObjectID: preferenceKey,
          Type: 'json',
          Name: 'filterModel',
          Value: JSON.stringify(gridApi.current.getFilterModel())
        },
        {
          URL: null,
          ObjectID: preferenceKey,
          Type: 'json',
          Name: 'sortModel',
          Value: JSON.stringify(gridApi.current.getSortModel())
        }
      ];

      setPromptSaveColumnSet(false);
      initGridPrefs(true);
    }
  }

  function clearFilters() {
    document.getElementById('filterTextBox').value = '';
    setSearchString(null);
    gridApi.current.setFilterModel(null);
    gridApi.current.onFilterChanged();
  }

  function getExportFileName() {
    var dtNow = new Date();
    var sTimeStamp =
      dtNow.getFullYear() +
      '-' +
      ((dtNow.getMonth() + 1 < 10 ? '0' : '') + (dtNow.getMonth() + 1)) +
      '-' +
      dtNow.getDate() +
      ' ' +
      dtNow.getHours() +
      '_' +
      dtNow.getMinutes() +
      '_' +
      dtNow.getSeconds();

    return 'Export_' + sTimeStamp;
  }

  function exportCsv() {
    setGridExportInProgress(true);

    props.actions
      .requestExport(
        '',
        getExportFileName() + '.csv',
        gridApi.current.getDataAsCsv()
      )
      .then(result => {
        setGridExportInProgress(false);
      });
  }

  function exportExcel() {
    gridApi.current.exportDataAsExcel({
      fileName: getExportFileName() + '.xlsx',
      columnGroups: true
    });
  }

  function onModelUpdated() {
    if (props.serverSideFieldList && columnApi.current) {
      if (props.columnDefs) {
        props.columnDefs.map(curColDef => {
          if (
            !props.serverSideFieldList.filter(
              field => field === curColDef.field
            ).length
          ) {
            columnApi.current.setColumnVisible(curColDef.field, false);
          } else {
            columnApi.current.setColumnVisible(curColDef.field, true);
          }
        });
      }
    }

    if (columnApi.current && !columnApi.current.defaultColumnState) {
      columnApi.current.defaultColumnState = columnApi.current.getColumnState();
    }
  }

  return (
    <>
      {!prefsApplied ? (
        <>
          <Skeleton height="100px" animation="wave" width="100%" />
          <Skeleton
            height="600px"
            width="100%"
            animation="wave"
            style={{ marginTop: '-140px' }}
          />
        </>
      ) : null}

      <div className={'mi-grid ' + (prefsApplied ? 'show' : 'hide')}>
        <div className="menu-bar">
          {columnSetList.length && props.trackAs && selectedColumnSet ? (
            <div className="col-set-list">
              <Button
                aria-controls="simple-menu"
                variant="contained"
                color="info"
                //color="primary"
                className="grid-button"
                aria-haspopup="true"
                onClick={handleColumnSetMenuButtonClick}>
                <AppsIcon />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={columnSetMenuOpen}
                keepMounted
                open={Boolean(columnSetMenuOpen)}
                onClose={handleColumnSetMenuButtonClosed}>
                {columnSetList.map(curColumnSet => (
                  <MenuItem
                    key={curColumnSet.title}
                    onClick={() => {
                      setColumnSet(curColumnSet.title);
                    }}>
                    <>{curColumnSet.title}</>
                  </MenuItem>
                ))}
                <Divider className="my-2" />
                <MenuItem
                  disabled={!gridCustomized}
                  onClick={() => {
                    setPromptSaveColumnSet(true);
                    handleColumnSetMenuButtonClosed();
                  }}>
                  Save Grid Changes
                </MenuItem>
              </Menu>

              <div className="col-set-name">
                {selectedColumnSet.title !== 'Default'
                  ? selectedColumnSet.title
                  : ''}
              </div>
            </div>
          ) : null}

          <div className="data-refresh">
            {props.liveUpdates ? (
              <Tooltip
                title="Grid receives live updates from the server"
                placement="top">
                <OfflineBoltIcon />
              </Tooltip>
            ) : props.refreshFunc ? (
              <Tooltip title="Click to refresh data" placement="top">
                <Button
                  variant="contained"
                  color="info"
                  //color="primary"
                  aria-haspopup="true"
                  onClick={props.refreshFunc}>
                  <CachedIcon />
                </Button>
              </Tooltip>
            ) : null}
          </div>

          <div className="export-button">
            <Tooltip
              title={
                gridExportInProgress ? 'Exporting...' : 'Export data to CSV'
              }
              placement="top">
              <Button
                variant="contained"
                color="info"
                //color="primary"
                aria-haspopup="true"
                disabled={gridExportInProgress}
                onClick={exportExcel}>
                <GetAppIcon />
              </Button>
            </Tooltip>
          </div>

          {/* <div className="column-jump">
            <Autocomplete
              id="columnJumpComboBox"
              options={columnJumpOptions}
              getOptionLabel={option => option.headerName}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Jump to column"
                  fullWidth
                  variant="outlined"
                />
              )}
              value={columnJumpInputValue}
              // inputValue={columnJumpInputValue}
              onChange={(event, newValue) => {
                if (newValue && newValue.field) {
                  columnApi.current.setColumnVisible(newValue.field, true);
                  gridApi.current.ensureColumnVisible(newValue.field);
                }

                document
                  .getElementsByClassName('column-jump')[0]
                  .getElementsByClassName('MuiAutocomplete-clearIndicator')[0]
                  .click();
              }}
            />
          </div> */}

          <div className="search">
            <TextField
              id="filterTextBox"
              variant="standard"
              placeholder="Search"
              autoComplete="off"
              onChange={event => {
                if (searchTimeout.current) {
                  clearTimeout(searchTimeout.current);
                }

                const _searchString = event.target.value;
                searchTimeout.current = setTimeout(() => {
                  setSearchString(_searchString);
                  gridApi.current.onFilterChanged();
                }, 150);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>

          {props.serverSideFieldList && props.serverSideFieldList.length ? (
            <div className="server-side-field-list">
              <Button
                color="primary"
                className="m-2"
                onClick={event => {
                  setServerSideFieldListVisible(event.currentTarget);
                }}>
                More fields <ExpandMoreIcon />
              </Button>
              <Popover
                open={Boolean(serverSideFieldListVisible)}
                anchorEl={serverSideFieldListVisible}
                onClose={() => setServerSideFieldListVisible(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}>
                <Box className="p-4">
                  {props.columnDefs.map(curColDef => {
                    return !curColDef.hide ? (
                      <FormGroup fullWidth={true}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                props.serverSideFieldList.filter(
                                  field => curColDef.field === field
                                ).length
                              }
                              onChange={event => {
                                props.setServerSideFieldList(
                                  props.serverSideFieldList.filter(
                                    field => curColDef.field === field
                                  ).length
                                    ? props.serverSideFieldList.filter(
                                        field => curColDef.field !== field
                                      )
                                    : props.serverSideFieldList.concat([
                                        curColDef.field
                                      ])
                                );
                              }}
                              name={curColDef.field}
                            />
                          }
                          label={curColDef.headerName}
                        />
                      </FormGroup>
                    ) : null;
                  })}

                  <Button
                    color="primary"
                    variant="contained"
                    className="m-2"
                    onClick={event => {
                      setServerSideFieldListVisible(null);
                      props.refreshFunc();
                    }}>
                    Update Grid
                  </Button>
                </Box>
              </Popover>
            </div>
          ) : null}

          {!props.hideDefaultTotals ? (
            <div className="counts">
              <div>
                {visibleRowCount !== totalRowCount ? (
                  <>
                    <span> {visibleRowCount} showing / </span>
                  </>
                ) : null}
                {totalRowCount ? totalRowCount : 0} total{' '}
              </div>
              {visibleRowCount !== (totalRowCount ? totalRowCount : 0) ? (
                <>
                  <Button
                    variant="outlined"
                    //color="primary"
                    className="clear-filters-button"
                    aria-haspopup="true"
                    onClick={clearFilters}>
                    Clear filters
                  </Button>
                </>
              ) : null}
            </div>
          ) : null}

          <div className="child-components">{props.children}</div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{
            height: '92%',
            width: '100%'
          }}>
          <AgGridReact
            groupHeaderHeight="30"
            columnDefs={columnDefs}
            rowData={props.rowData}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            onColumnMoved={onColumnMoved}
            onColumnVisible={onColumnVisible}
            onColumnPinned={onColumnPinned}
            onColumnResized={onColumnResized}
            onCellFocused={props.onCellFocused}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            quickFilterText={searchString}
            enableCellChangeFlash="true"
            cellFlashDelay={1000}
            immutableData={props.immutableData}
            getRowNodeId={props.getRowNodeId}
            domLayout={props.autoHeight ? 'autoHeight' : null}
            onCellEditingStarted={props.onCellEditingStarted}
            onCellEditingStopped={props.onCellEditingStopped}
            suppressContextMenu={true}
            onSelectionChanged={props.onSelectionChanged}
            onRangeSelectionChanged={props.onRangeSelectionChanged}
            rowSelection={props.rowSelection}
            allowShowChangeAfterFilter={false}
            rowDeselection={true}
            onRowClicked={props.onRowClicked}
            overlayLoadingTemplate={props.overlayLoadingTemplate}
            overlayNoRowsTemplate={props.overlayNoRowsTemplate}
            // suppressRowClickSelection={
            //   props.hasOwnProperty('suppressRowClickSelection')
            //     ? props.suppressRowClickSelection
            //     : true
            // }
            enableRangeSelection={true}
            autoGroupColumnDef={props.autoGroupColumnDef}
            suppressMovableColumns={isMobile()}
            getRowClass={props.getRowClass}
            rowHeight={props.rowHeight}
            groupDefaultExpanded={props.groupDefaultExpanded}
            groupSuppressAutoColumn={props.groupSuppressAutoColumn}
            suppressAggFuncInHeader={true}
            groupHideOpenParents={props.groupHideOpenParents}
            onModelUpdated={onModelUpdated}
            suppressClipboardPaste={true}
            onBodyScroll={() => {
              //TODO - make this work for the react grid
              // setTimeout(() => {
              //   $(".ag-header-group-cell-label").each(function () {
              //     var containerLeftPosition = $(this)
              //       .closest(".ag-header-container")
              //       .css("left");
              //     if (containerLeftPosition) {
              //       var containerOffset = parseInt(containerLeftPosition);
              //       var headerOffset = parseInt(
              //         $(this).closest(".ag-header-group-cell").css("left")
              //       );
              //       var newMargin = 0;
              //       if (
              //         containerOffset &&
              //         containerOffset < 0 &&
              //         !isNaN(headerOffset)
              //       ) {
              //         newMargin = containerOffset * -1 - headerOffset;
              //       }
              //       if (newMargin > 0) {
              //         $(this).css("margin-left", newMargin + "px");
              //       } else {
              //         $(this).css("margin-left", "");
              //       }
              //     }
              //   });
              // }, 0);
            }}></AgGridReact>
        </div>
      </div>

      <Dialog
        open={promptSaveColumnSet}
        aria-labelledby="form-dialog-title"
        fullWidth>
        <DialogTitle id="form-dialog-title">Save Column Set</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Column Set to Update"
              name="column-save-option"
              value={columnSetSaveOption}
              onChange={event => {
                setColumnSetSaveOption(event.target.value);
              }}>
              <FormControlLabel
                value="overwrite"
                control={<Radio />}
                label={
                  'Overwrite ' +
                  (selectedColumnSet ? selectedColumnSet.title : '')
                }
              />
              <FormControlLabel
                value="new"
                control={<Radio />}
                label="Save New"
              />
            </RadioGroup>
          </FormControl>
          {columnSetSaveOption === 'new' ? (
            <FormControl
              component="fieldset"
              error={newColumnSetError !== null}
              fullWidth>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                label="Column Set Name"
                type="text"
                fullWidth
                value={newColumnSetTitle}
                onChange={event => {
                  setNewColumnSetTitle(event.target.value);
                }}
                required
              />
              <>
                {newColumnSetError ? (
                  <FormHelperText>{newColumnSetError}</FormHelperText>
                ) : null}
              </>
            </FormControl>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPromptSaveColumnSet(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmSaveColumnSet}
            color="primary"
            disabled={columnSetSaveOption === 'new' && !newColumnSetTitle}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

Grid.propTypes = {
  columnDefs: PropTypes.array.isRequired,
  rowData: PropTypes.array,
  defaultColDef: PropTypes.object,
  onGridReady: PropTypes.func
};

export default Grid;
