import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { deviceDetect } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';

import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';

import * as authActions from '../redux/actions/authActions';
import {
  registerUser,
  getEmailVerificationCode,
  getTextVerificationCode,
  generateUUID
} from '../api/authApi';

import { getCookie, setCookie } from '../api/apiConfig';

import HomePage from './Home';
import Header from './Header';
import Footer from './Footer';
import PageNotFound from './PageNotFound';
import LoginForm from './LoginForm';
import MyProfile from './MyProfile';
import BillingHistory from './BillingHistory';
import ViewAllNotifications from './ViewAllNotifications';
import Curriculum from './Curriculum';
import Transcript from './Transcript';
import Admin from './Admin';
import DocuSignComplete from './DocuSignComplete';
import JobListingSearch from './JobListingSearch';
import TestUser from './Admin/TestUser';

import './index.css';

function App({ auth, actions }) {
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      String(window.location.href).indexOf('localhost') === -1
    ) {
      if (window.location.protocol === 'http:') {
        window.location.href = window.location.href.replace('http:', 'https:');
      }
    }
  }, []);

  useEffect(() => {
    let deviceUniqueId = getCookie('adi-visitor');
    if (!deviceUniqueId) {
      deviceUniqueId = generateUUID();
      setCookie('adi-visitor', deviceUniqueId);
    }

    if (deviceUniqueId) {
      actions.deviceReady({
        ...deviceDetect(),
        deviceUniqueId
      });
    }
  }, []);

  if (auth) {
    return window.location.hash === '#/job-board' ? (
      <JobListingSearch />
    ) : window.location.hash &&
      window.location.hash.indexOf('#/test-user') === 0 ? (
      <TestUser />
    ) : (
      <>
        <Header />
        <div className="main-app-content">
          {auth.user && auth.user.IsLoggedIn ? (
            auth.user.UserLevelID === 9 ? (
              <Admin />
            ) : (
              <>
                <Switch>
                  <Route exact path="/" component={HomePage} />
                  <Route path="/my-profile" component={MyProfile} />
                  <Route path="/billing-history" component={BillingHistory} />
                  <Route path="/curriculum" component={Curriculum} />
                  <Route path="/transcript" component={Transcript} />
                  <Route path="/sign-complete" component={DocuSignComplete} />
                  <Route path="/job-board" component={JobListingSearch} />
                  <Route path="/test-user" component={TestUser} />
                  <Route
                    path="/all-notifications"
                    component={ViewAllNotifications}
                  />
                  <Route component={PageNotFound} />
                </Switch>
              </>
            )
          ) : (
            <LoginForm />
          )}
        </div>

        <Footer />
        {auth.isLoading ? (
          <div className="loading-overlay">
            <div className="loading-message">
              <CircularProgress />
              <br></br>
              {auth.loadingMessage ? auth.loadingMessage : 'Loading...'}
            </div>
          </div>
        ) : null}
      </>
    );
  } else {
    return (
      <div className="splash">
        <LinearProgress />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
