import * as api from './apiConfig';

export function listUser() {
  return api.get('API/AdminData/ListUser').then(response => response.data);
}

export function sendUserNotification(notification) {
  return api
    .post('API/AdminData/SendUserNotification', notification)
    .then(response => response.data);
}

export function updateUserDateExpires(userID, daysOut) {
  return api
    .post(
      'API/AdminData/UpdateUserDateExpires?userID=' +
        userID +
        '&daysOut=' +
        daysOut
    )
    .then(response => response.data);
}

export function updateUserIsActive(userID, isActive) {
  return api
    .post(
      'API/AdminData/UpdateUserIsActive?userID=' +
        userID +
        '&isActive=' +
        (isActive ? 'true' : 'false')
    )
    .then(response => response.data);
}

export function listUserActivity(userID) {
  return api
    .get('API/AdminData/ListUserActivity' + (userID ? '?userID=' + userID : ''))
    .then(response => response.data);
}

export function listUserDevice(userID) {
  return api
    .get('API/AdminData/ListUserDevice?userID=' + userID)
    .then(response => response.data);
}

export function listUserNotificationAdmin(userID) {
  return api
    .get('API/AdminData/ListUserNotification?userID=' + userID)
    .then(response => response.data);
}

export function saveAnnouncement(announcement) {
  return api
    .post('API/AdminData/SaveAnnouncement', announcement)
    .then(response => response.data);
}

export function deleteAnnouncement(announcementID) {
  return api
    .post('API/AdminData/DeleteAnnouncement?announcementID=' + announcementID)
    .then(response => response.data);
}

export function updateDiplomaIssueDate(userID, dateIssued) {
  return api
    .post(
      'API/AdminData/UpdateDiplomaIssueDate?userID=' +
        userID +
        '&dateIssued=' +
        encodeURIComponent(dateIssued)
    )
    .then(response => response.data);
}

export function listPromoCode() {
  return api.get('API/AdminData/ListPromoCode').then(response => response.data);
}

export function savePromoCode(promoCode) {
  return api
    .post('API/AdminData/SavePromoCode', promoCode)
    .then(response => response.data);
}

export function deletePromoCode(promoCode) {
  return api
    .post('API/AdminData/DeletePromoCode', promoCode)
    .then(response => response.data);
}

export function deleteUser(userID) {
  return api
    .post('API/AdminData/DeleteUser?userID=' + userID)
    .then(response => response.data);
}

export function listEmail(mailTo) {
  return api
    .get('API/AdminData/ListEmail?mailTo=' + encodeURIComponent(mailTo))
    .then(response => response.data);
}

export function listJobListing() {
  return api
    .get('API/AdminData/ListJobListing')
    .then(response => response.data);
}

export function importJobListing(fileName) {
  return api
    .post(
      'API/AdminData/ImportJobListing?fileName=' + encodeURIComponent(fileName)
    )
    .then(response => response.data);
}

export function deleteJobListing(jobListingID) {
  return api
    .post('API/AdminData/DeleteJobListing?jobListingID=' + jobListingID)
    .then(response => response.data);
}
