import React, { useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
function GridVisibilityIcon(params) {
  return (
    <div className="mi-grid-icon-button">
      <IconButton>{params.value ? <EmailIcon /> : null}</IconButton>
    </div>
  );
}

GridVisibilityIcon.propTypes = {
  params: PropTypes.object
};

export default GridVisibilityIcon;
