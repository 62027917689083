import React from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AccountMenu from './AccountMenu';
import NotificationBell from './NotificationBell';
import './index.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../redux/actions/authActions';

const Header = ({ auth, actions }) => {
  return (
    <>
      <div className="blue-bar-above-header">
        <br />
        <br />
      </div>

      <nav>
        {auth && auth.user && auth.user.IsLoggedIn && auth.user.IsActive ? (
          <>
            <div className="user-profile">
              <AccountMenu />
            </div>
            <div className="notification">
              {auth.user.UserLevelID !== 9 ? (
                <Button>
                  <NotificationBell />
                </Button>
              ) : null}
            </div>
          </>
        ) : null}

        <a href="/">
          <img
            className="adi-logo"
            src="\images\adi-light-logo.png"
            alt="ADI"></img>
        </a>
      </nav>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
