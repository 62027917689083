import React, { useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

function GridDropdownField(params) {
  const changeTimeout = useRef();
  const initted = useRef();
  const [saving, setSaving] = useState(false);
  const fieldID = params.data
    ? 'mi-grid-input_' +
      params.data[params.colDef.field] +
      '_' +
      params.colDef.field
    : '';

  const filterOptions = createFilterOptions({
    limit: 100
  });

  const [selectedOptionList, setSelectedOptionList] = useState([params.value]);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (changeTimeout.current) {
        clearTimeout(changeTimeout.current);
      }
    };
  }, []);

  function valueChanged(newValue) {
    if (params.colDef.onDataChanged) {
      if (changeTimeout.current) {
        clearTimeout(changeTimeout.current);
      }

      changeTimeout.current = setTimeout(() => {
        setSaving(true);
        params.colDef
          .onDataChanged(params.data, params.colDef.field, newValue)
          .then(result => setSaving(false));
      }, 100);
    }
  }

  return fieldID ? (
    <div className="mi-dropdown-menu">
      <Autocomplete
        multiple={params && params.colDef && params.multiple}
        id="provider-assign-search"
        filterOptions={filterOptions}
        options={
          params.colDef.optionListFunc
            ? params.colDef.optionListFunc()
            : params.colDef.optionList
        }
        getOptionLabel={option => option || ''}
        defaultValue={selectedOptionList}
        onChange={(event, value) => {
          setSelectedOptionList(value);
          valueChanged(value);
        }}
        disabled={saving}
        fullWidth={true}
        renderInput={props => (
          <TextField
            {...props}
            variant="standard"
            type={
              params.colDef && params.colDef.numbersOnly ? 'number' : 'text'
            }
            //placeholder="Search options"
          />
        )}
      />
    </div>
  ) : null;
}

GridDropdownField.propTypes = {
  params: PropTypes.object
};

export default GridDropdownField;
