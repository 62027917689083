import React, { useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import { Tooltip } from '@mui/material';

function GridToggleField(params) {
  const [value, setValue] = useState(params.value);
  const changeTimeout = useRef();
  const [saving, setSaving] = useState(false);
  const fieldID = params.data
    ? 'mi-grid-input_' +
      params.data[params.colDef.field] +
      '_' +
      params.colDef.field
    : '';

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (changeTimeout.current) {
        clearTimeout(changeTimeout.current);
      }
    };
  }, []);

  function onChange(e) {
    try {
      setValue(e.target.checked);
      setSaving(true);
      params.colDef
        .onDataChanged(
          params.data,
          params.colDef.field,
          e.target.checked ? true : false
        )
        .then(result => setSaving(false));
    } catch (e) {}
  }

  function getTooltip() {
    if (params.colDef.disableFunc) {
      const disableMessage = checkDisabled();
      return disableMessage
        ? disableMessage
        : params.value
        ? 'Click to turn off access'
        : 'Click to allow access';
    } else if (params.colDef.tooltipFunc) {
      return params.colDef.tooltipFunc(params);
    }
    return '';
  }

  function checkDisabled() {
    if (params.colDef.disableFunc) {
      return params.colDef.disableFunc(params);
    }

    return false;
  }

  return fieldID ? (
    <Tooltip title={getTooltip()} placement="left">
      <span>
        <Switch
          disabled={saving || checkDisabled()}
          checked={checkDisabled() ? false : value}
          onChange={onChange}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </span>
    </Tooltip>
  ) : null;
}

GridToggleField.propTypes = {
  params: PropTypes.object
};

export default GridToggleField;
