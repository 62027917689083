import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85'
    },
    neutral: {
      main: '#d4af37',
      contrastText: '#ffffff'
    },
    answered: {
      main: '#000135',
      contrastText: '#d4af37'
    },
    notAnswered: {
      main: '#FFFFFF',
      contrastText: '#d4af37'
    },
    white: {
      main: '#FFFFFF',
      contrastText: '#000135'
    }
  }
});

function AdiThemeProvider({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default AdiThemeProvider;
