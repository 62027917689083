import * as React from "react";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "../../redux/actions/authActions";
import { listUserNotification } from "../../api/authApi";
import NotificationMessage from "../NotificationMessage";

function NotificationBell({ auth, actions, notification }) {
  const [showAccountMenu, setShowAccountMenu] = React.useState(null);
  const [notificationList, setNotificationList] = useState(null);

  useEffect(() => {
    if (showAccountMenu) {
      listUserNotification(false).then((_notificationList) => {
        setNotificationList(_notificationList);
        actions.checkAuthToken();
      });
    }
  }, [showAccountMenu]);

  return (
    <>
      {auth.user.UserLevelID !== 9 ? (
        <Badge
          badgeContent={auth.user.NotificationCount}
          color="primary"
          onClick={(event) => {
            setShowAccountMenu(event.currentTarget);
          }}
        >
          <NotificationsNoneIcon />
        </Badge>
      ) : null}

      {auth.user.UserLevelID !== 9 ? (
        <Popover
          className="notification-menu-popover"
          id={showAccountMenu ? "notification-menu-popover" : undefined}
          open={Boolean(showAccountMenu)}
          anchorEl={showAccountMenu}
          onClose={() => {
            setShowAccountMenu(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="notification-menu">
            <div className="notification-list">
              {notificationList && notificationList.length ? (
                notificationList.map((notification) => (
                  <NotificationMessage
                    notification={notification}
                    key={notification.UserNotificationID}
                  />
                ))
              ) : (
                <p className="no-notification-message">
                  You have no new notifications.
                </p>
              )}

              {auth.user.UserLevelID !== 9 ? (
                <Button
                  className="notification-button"
                  onClick={() => {
                    setShowAccountMenu(null);
                  }}
                  component={Link}
                  to="/all-notifications"
                >
                  View All Notifications
                </Button>
              ) : null}
            </div>
          </div>
        </Popover>
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBell);
