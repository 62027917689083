import React from 'react';
import ReactDOM from 'react-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { HashRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import configureStore from './redux/configureStore';

import 'bootstrap/dist/css/bootstrap.min.css';

import App from 'components/App';

const store = configureStore();

ReactDOM.render(
  <PayPalScriptProvider
    options={{
      'client-id':
        //'AYNOJPe8Dwq-V-pLVLvp4r6oP448Ci6RoJluNLVHTH21J-EzKm8VmnV0vVx6btEiVxb_3tHzyFeg_mne', //Sandbox
        'AbQnMIvHgPD94O4jSF8GhVSuc_jUqny9lXOUYM-Vy1FkUiJrA0iFHnEnPAf9_QNkOLw0wsBwfR4jF-5F', //Live
      currency: 'USD'
    }}>
    <ReduxProvider store={store}>
      <Router>
        <App />
      </Router>
    </ReduxProvider>
  </PayPalScriptProvider>,
  document.getElementById('root')
);
