import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdiThemeProvider from '../AdiThemeProvider';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DeleteIcon from '@mui/icons-material/Delete';

import { saveAnnouncement, deleteAnnouncement } from 'api/adminDataApi';
import { listAnnouncement } from 'api/studentDataApi';

import './index.css';

function AdminAnnouncements({ student, closeFunc, onUserAdded }) {
  const [editMode, setEditMode] = useState(false);
  const [announcementList, setAnnouncementList] = useState(null);

  useEffect(() => {
    loadAnnouncementList();
  }, []);

  function loadAnnouncementList() {
    listAnnouncement().then(_announcementList =>
      setAnnouncementList(_announcementList)
    );
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      fullScreen={true}
      className="admin-register-popup">
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        Announcements
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '0px' }}>
        <>
          <div>
            <div className="announcement-side">
              {announcementList ? (
                announcementList.length ? (
                  announcementList.map(announcement => (
                    <div className="announcement">
                      <div className="announcement-content">
                        <p>
                          <strong>{announcement.AnnouncementTypeName}</strong>
                        </p>
                        <h6>{announcement.Title}</h6>
                        <p>{announcement.Description}</p>
                      </div>
                      {announcement.Url ? (
                        <div className="launch-button">
                          <IconButton href={announcement.Url} target="_blank">
                            <LaunchIcon />
                          </IconButton>
                        </div>
                      ) : null}
                      <div className="edit-button">
                        <IconButton
                          onClick={() => {
                            setEditMode(null);
                            setTimeout(
                              () => setEditMode({ ...announcement }),
                              100
                            );
                          }}>
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            deleteAnnouncement(
                              announcement.AnnouncementID
                            ).then(result => {
                              loadAnnouncementList();
                            });
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="empty">No announcements have been added.</div>
                )
              ) : (
                <LinearProgress />
              )}
            </div>

            <div className="edit-add-announcement-side">
              <div className="add-button">
                {editMode ? (
                  <>
                    <div className="add-announcement-header">
                      <p>
                        {editMode.AnnouncementID === 0 ? 'Add' : 'Edit'} an
                        Announcement
                      </p>
                    </div>
                    <TextField
                      id="standard-basic"
                      label="Announcement Title"
                      value={editMode.Title}
                      variant="standard"
                      fullWidth
                      className="text-field"
                      onChange={event =>
                        setEditMode({ ...editMode, Title: event.target.value })
                      }
                    />

                    <TextField
                      id="standard-basic"
                      label="Description"
                      value={editMode.Description}
                      variant="standard"
                      fullWidth
                      className="text-field"
                      onChange={event =>
                        setEditMode({
                          ...editMode,
                          Description: event.target.value
                        })
                      }
                    />

                    <TextField
                      id="standard-basic"
                      label="URL"
                      value={editMode.Url}
                      variant="standard"
                      fullWidth
                      className="text-field"
                      placeholder="https://"
                      onChange={event =>
                        setEditMode({ ...editMode, Url: event.target.value })
                      }
                    />

                    <div className="radio-and-buttons">
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Announcement Type
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={editMode.AnnouncementTypeID}
                          onChange={(event, newValue) =>
                            setEditMode({
                              ...editMode,
                              AnnouncementTypeID: parseInt(newValue)
                            })
                          }>
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Announcement"
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Job Placement Resource"
                          />
                        </RadioGroup>
                      </FormControl>

                      <div className="save-button">
                        <AdiThemeProvider>
                          <Button
                            onClick={() => {
                              saveAnnouncement(editMode).then(result => {
                                setEditMode(null);
                                loadAnnouncementList();
                              });
                            }}
                            variant="contained"
                            color="neutral"
                            disabled={
                              (editMode.Url &&
                                editMode.Url.indexOf('http') !== 0) ||
                              !editMode.Title
                            }>
                            Save
                          </Button>
                        </AdiThemeProvider>
                      </div>

                      <Button
                        onClick={() => {
                          setEditMode(null);
                        }}
                        variant="outlined">
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      setEditMode({ AnnouncementID: 0 });
                    }}
                    variant="outlined">
                    Add New Announcement
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdminAnnouncements;
