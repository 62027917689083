import React, { useState, useRef } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

function GridCalendar(params) {
  const [value, setValue] = useState(params.value ? dayjs(params.value) : null);
  const [saving, setSaving] = useState(false);

  console.log();

  const changeTimeout = useRef();

  const handleDateChange = newValue => {
    setValue(newValue);
    valueChanged(moment(newValue.$d).format('YYYY-MM-DD'));
  };

  function valueChanged(newValue) {
    if (params.colDef.onDataChanged) {
      if (changeTimeout.current) {
        clearTimeout(changeTimeout.current);
      }

      changeTimeout.current = setTimeout(() => {
        setSaving(true);
        params.colDef
          .onDataChanged(params.data, params.colDef.field, newValue)
          .then(result => setSaving(false));
      }, 100);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopDatePicker
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleDateChange}
          renderInput={params => (
            <TextField
              disabled={saving}
              fullWidth
              variant="standard"
              {...params}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export default GridCalendar;
