import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

import * as studentDataActions from '../../redux/actions/studentDataActions';
import LessonIcon from '../LessonIcon';
import TestIcon from '../TestIcon';

import './index.css';

function Transcript({ auth, actions, studentData, popUp }) {
  useEffect(() => {
    if (!studentData.curriculum) {
      actions.loadCurriculum();
    }
  }, []);

  useEffect(() => {
    if (
      !studentData.dash ||
      !Object.keys(studentData.dash).length ||
      (studentData.userID && studentData.dash.UserID != studentData.userID)
    ) {
      actions.loadStudentDashboard(studentData.userID ? studentData.userID : 0);
    }
  }, []);

  useEffect(() => {
    console.log('studentData', studentData);
  }, [studentData]);

  return studentData.curriculum ? (
    <div className="page-container transcript-page">
      <div className="page">
        {!popUp ? (
          <h1>
            <IconButton component={Link} to={'/'}>
              <ArrowBackIcon />
            </IconButton>
            Transcript
          </h1>
        ) : null}
        <div className="gpa-container">
          <div className="gpa-label">Total GPA:</div>
          <div className="overall-gpa">
            {studentData && studentData.dash && studentData.dash.GPA
              ? studentData.dash.GPA.toFixed(1)
              : '0.0'}
          </div>
        </div>
        <TableContainer
          sx={{
            height: 500
          }}
          component={Paper}>
          <Table
            sx={{
              height: 'max-content'
            }}
            aria-label="simple table"
            stickyHeader>
            <TableHead className="table-header">
              <TableRow>
                <TableCell className="header-title">Section</TableCell>
                <TableCell className="header-title">Chapter Name</TableCell>
                <TableCell className="header-title">Lesson Name</TableCell>
                <TableCell className="header-title">Completed</TableCell>
                <TableCell className="header-title">Result</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentData.curriculum.map(section =>
                section.ChapterList.map(chapter => (
                  <>
                    {chapter.LessonList.map((lesson, lessonIndex) => (
                      <TableRow
                        className={lessonIndex === 0 ? 'first-name' : null}
                        key={'lesson_' + lesson.SectionChapterLessonID}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}>
                        <TableCell scope="row">{section.SectionName}</TableCell>
                        <TableCell>{chapter.SectionChapterName}</TableCell>
                        <TableCell>
                          {lesson.LessonName.split('\n')[0]}
                        </TableCell>
                        <TableCell>
                          <LessonIcon lesson={lesson} />
                        </TableCell>
                        <TableCell>--</TableCell>
                      </TableRow>
                    ))}

                    <TableRow
                      key={'test_' + chapter.SectionChapterID}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}>
                      <TableCell scope="row">{section.SectionName}</TableCell>
                      <TableCell>{chapter.SectionChapterName}</TableCell>
                      <TableCell>Exam</TableCell>
                      <TableCell>
                        <TestIcon test={chapter.Test} />
                      </TableCell>
                      <TableCell>
                        {chapter.Test.Grade && chapter.Test.IsComplete
                          ? chapter.Test.Grade
                          : '--'}
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    studentData: state.studentData,
    commonData: state.commonData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(studentDataActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Transcript);
