import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import * as studentDataActions from "../../redux/actions/studentDataActions";
import { listUserDevice } from "../../api/adminDataApi";

import "./index.css";

function DeviceListPopup({ student, closeFunc }) {
  const [userDeviceList, setUserDeviceList] = useState(null);

  useEffect(() => {
    listUserDevice(student.UserID).then((result) => {
      setUserDeviceList(result);
    });
  }, []);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="device-list-popup"
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: "right" }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}
        >
          <CloseIcon />
        </IconButton>
        Device List for {student.FirstName} {student.LastName}
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: "20px" }}>
        {userDeviceList ? (
          <div>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead className="device-table-head">
                  <TableRow>
                    <TableCell className="device-header-title">
                      Device Identifier
                    </TableCell>
                    <TableCell className="device-header-title">
                      Browser
                    </TableCell>
                    <TableCell className="device-header-title">
                      Device Type
                    </TableCell>
                    <TableCell className="device-header-title">
                      Email Verified
                    </TableCell>
                    <TableCell className="device-header-title">
                      Text Verified
                    </TableCell>
                    <TableCell className="device-header-title">
                      IP Address
                    </TableCell>
                    <TableCell className="device-header-title">
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userDeviceList.map((device) => (
                    <TableRow key={device.DeviceUniqueId}>
                      <TableCell>{device.DeviceUniqueId}</TableCell>
                      <TableCell>{device.Browser}</TableCell>
                      <TableCell>{device.DeviceType}</TableCell>
                      <TableCell>
                        {device.EmailVerified
                          ? moment
                              .utc(device.EmailVerified)
                              .local()
                              .format("MM/DD/YYYY")
                          : "Not yet verified"}
                      </TableCell>
                      <TableCell>
                        {device.TextVerified
                          ? moment
                              .utc(device.TextVerified)
                              .local()
                              .format("MM/DD/YYYY")
                          : "Not yet verified"}
                      </TableCell>
                      <TableCell>{device.IPAddress}</TableCell>
                      <TableCell>
                        {device.IsActive ? (
                          <p className="device-active">Active</p>
                        ) : (
                          "Not Active"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions style={{ display: "block", textAlign: "right" }}>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeviceListPopup;
