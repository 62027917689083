import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import {
  getLessonVideo,
  updateLessonVideoTime,
  updateLessonVideoEnded,
  getSectionPDF
} from '../../api/studentDataApi';

import * as studentDataActions from '../../redux/actions/studentDataActions';
import * as authActions from '../../redux/actions/authActions';

import { scrollToTop } from '../CommonFunctions';

import DashBoardItem from '../StudentDashboard/DashboardItem';
import LessonIcon from '../LessonIcon';
import TestIcon from '../TestIcon';
import VideoJS from '../VideoJS';
import Test from './Test';

import './index.css';

function LessonName({ lessonName }) {
  return (
    <div
      className="lesson-name"
      dangerouslySetInnerHTML={{
        __html: lessonName
      }}></div>
  );
}

function Curriculum({ auth, actions, studentData }) {
  const loadingTimeout = useRef();
  const videoTime = useRef();
  const videoProgressInterval = useRef();
  const playerRef = React.useRef(null);

  const [expandedSection, setExpandedSection] = useState(false);
  const [activeLesson, setActiveLesson] = useState(null);
  const [activeTest, setActiveTest] = useState(null);
  const [videoJsOptions, setVideoJsOptions] = useState({
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true
  });

  const [activeVideoTime, setActiveVideoTime] = useState(null);

  useEffect(() => {
    if (!studentData.curriculum) {
      actions.loadCurriculum();
    }
  }, []);

  useEffect(() => {
    if (activeLesson) {
      scrollToTop();

      //Load the video URL
      loadingTimeout.current = setTimeout(() => {
        actions.setDataLoading(true);
      }, 500);

      if (videoProgressInterval.current) {
        clearInterval(videoProgressInterval.current);
      }

      videoTime.current = null;
      setVideoJsOptions({ ...videoJsOptions, sources: [] });

      getLessonVideo(
        activeLesson.SectionID,
        activeLesson.SectionChapterID,
        activeLesson.SectionChapterLessonID
      ).then(videoUrl => {
        if (loadingTimeout.current) {
          clearTimeout(loadingTimeout.current);
        }

        setVideoJsOptions({
          ...videoJsOptions,
          sources: [
            {
              src: videoUrl,
              type: 'video/mp4'
            }
          ]
        });

        actions.setDataLoading(false);
      });
    }
  }, [activeLesson]);

  useEffect(() => {
    setActiveLesson(null);
    if (videoProgressInterval.current) {
      clearInterval(videoProgressInterval.current);
    }

    scrollToTop();
  }, [activeTest]);

  useEffect(() => {
    console.log('studentData', studentData);
    if (studentData.curriculum && !expandedSection) {
      let nextSectionID = 0;
      studentData.curriculum.map(section => {
        section.ChapterList.map(chapter => {
          const availableLessonList = chapter.LessonList.filter(
            _lesson => _lesson.IsAvailable
          );

          if (availableLessonList.length) {
            const nextLesson =
              availableLessonList[availableLessonList.length - 1];
            nextSectionID = nextLesson.SectionID;
          }
        });
      });

      if (nextSectionID) {
        setExpandedSection(nextSectionID);
      }
    }
  }, [studentData]);

  useEffect(() => {
    setTimeout(() => {
      const elements = document.getElementsByClassName('Mui-expanded');
      const element = elements[0];
      const offset = -100;

      if (element) {
        let scrollArgs = {
          top: element.getBoundingClientRect().top + window.pageYOffset + offset
        };
        // if (!instant) {
        //   scrollArgs.behavior = 'smooth';
        // }
        window.scrollTo(scrollArgs);
      }
    }, 400);
  }, [expandedSection]);

  const handlePlayerReady = player => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  return studentData.curriculum ? (
    <div className="page-container curriculum-page">
      <div className="page">
        <h1>
          <IconButton component={Link} to={'/'}>
            <ArrowBackIcon />
          </IconButton>
          {!activeTest && !activeLesson ? (
            <>
              <a className="dashboard-link" href="/">
                Dashboard
              </a>{' '}
              | Curriculum
            </>
          ) : (
            <>
              <a className="dashboard-link" href="/">
                Dashboard
              </a>{' '}
              |{' '}
              <a
                className="dashboard-link"
                onClick={() => {
                  setActiveTest(null);
                  setActiveLesson(null);
                }}>
                Curriculum
              </a>
            </>
          )}
        </h1>

        {activeTest ? (
          <Test activeTest={activeTest} setActiveTest={setActiveTest} />
        ) : (
          <>
            {activeLesson ? (
              <div className="top-container">
                <div className="video-title">
                  <h5>
                    <span>{activeLesson.SectionName}</span> <ChevronRightIcon />{' '}
                    <span>{activeLesson.SectionChapterName}</span>{' '}
                    <ChevronRightIcon /> <span>{activeLesson.LessonName}</span>
                  </h5>
                </div>
                <div className="video-container">
                  <div className="video">
                    {videoJsOptions &&
                    videoJsOptions.sources &&
                    videoJsOptions.sources.length ? (
                      <VideoJS
                        options={videoJsOptions}
                        onReady={handlePlayerReady}
                        onPlay={() => {
                          videoProgressInterval.current = setInterval(() => {
                            const videoTimeString = videoTime.current
                              ? new Date(videoTime.current * 1000)
                                  .toISOString()
                                  .substring(14, 19)
                              : '00:00';

                            updateLessonVideoTime(
                              activeLesson.SectionChapterLessonID,
                              videoTimeString
                            );
                          }, 5000);

                          console.log('track video played');
                        }}
                        onPause={() => {
                          if (videoProgressInterval.current) {
                            clearInterval(videoProgressInterval.current);
                          }

                          console.log('track video paused');
                        }}
                        onTimeUpdate={currentTime => {
                          videoTime.current = currentTime;
                        }}
                        onEnded={() => {
                          if (videoProgressInterval.current) {
                            clearInterval(videoProgressInterval.current);
                          }

                          updateLessonVideoEnded(
                            activeLesson.SectionChapterLessonID
                          ).then(result => actions.loadCurriculum());

                          console.log('track video ended');
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="curriculum-list-container">
              {studentData.curriculum.map(section => (
                <>
                  <div className="each-accordion">
                    <Accordion
                      key={section.SectionID}
                      expanded={expandedSection === section.SectionID}
                      onChange={() =>
                        setExpandedSection(
                          expandedSection === section.SectionID
                            ? null
                            : section.SectionID
                        )
                      }>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <b>{section.SectionName}</b>

                        {section.PDFFileDownload ? (
                          <Button
                            className="download-pdf-tip"
                            variant="outlined"
                            style={{ marginTop: '-5px' }}
                            onClick={event => {
                              getSectionPDF(section.PDFFileDownload).then(
                                url => {
                                  if (url) {
                                    window.location = url;
                                  }
                                }
                              );
                              event.stopPropagation();
                            }}>
                            Please download Section PDFs before proceeding{' '}
                            <FileDownloadIcon />
                          </Button>
                        ) : null}
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul className="inner-content">
                          {section.ChapterList.map(chapter => (
                            <li type="none" key={chapter.SectionChapterID}>
                              <TestIcon test={chapter.Test} />
                              {chapter.SectionChapterName}
                              <div className="each-chapter">
                                <ul>
                                  {chapter.LessonList.map(lesson => (
                                    <li
                                      type="none"
                                      key={lesson.SectionChapterLessonID}
                                      className={
                                        'lesson ' +
                                        (lesson.IsAvailable ? ' available' : '')
                                      }>
                                      <LessonIcon lesson={lesson} />

                                      {lesson.IsAvailable
                                        ? lesson.LessonName.split('\n').map(
                                            (lessonName, lessonNameIndex) =>
                                              lessonNameIndex === 0 ? (
                                                <a
                                                  onClick={() =>
                                                    setActiveLesson(lesson)
                                                  }>
                                                  <LessonName
                                                    lessonName={lessonName}
                                                  />
                                                </a>
                                              ) : (
                                                <div className="lesson-name-secondary active">
                                                  {lessonName}
                                                </div>
                                              )
                                          )
                                        : lesson.LessonName.split(
                                            '\n'
                                          ).map((lessonName, lessonNameIndex) =>
                                            lessonNameIndex === 0 ? (
                                              <LessonName
                                                lessonName={lessonName}
                                              />
                                            ) : (
                                              <div className="lesson-name-secondary">
                                                {lessonName}
                                              </div>
                                            )
                                          )}
                                    </li>
                                  ))}

                                  <li
                                    type="none"
                                    className="lesson"
                                    key={'test_' + chapter.SectionChapterID}>
                                    <TestIcon test={chapter.Test} />

                                    {chapter.Test.IsAvailable ? (
                                      <a
                                        onClick={() =>
                                          setActiveTest(chapter.Test)
                                        }>
                                        Exam
                                      </a>
                                    ) : (
                                      <>Exam</>
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          ))}
                        </ul>

                        <div className="section-progress">
                          <h5 className="progress-title">Section Progress</h5>
                          <div className="progress-summary">
                            <DashBoardItem
                              progress={section.Progress}
                              progressLabel={section.Progress + '%'}
                            />
                          </div>
                          <div className="line"></div>
                          {section.ChapterList.filter(
                            chapter =>
                              chapter.Test && chapter.Test.QuestionCountAnswered
                          ).map(chapter => (
                            <>
                              <div className="progress-content">
                                <h6 className="chapter-title">
                                  Exam: {chapter.SectionChapterName}
                                </h6>
                                {chapter.Test.IsPassed ? (
                                  <h5>Congratulations! You passed.</h5>
                                ) : (
                                  <h5>Please try again.</h5>
                                )}

                                <div className="score">
                                  <h6>Exam Score:</h6>
                                  <p>
                                    <CheckCircleIcon
                                      sx={{ color: '#24cb27' }}
                                    />{' '}
                                    {chapter.Test.QuestionCountCorrect
                                      ? chapter.Test.QuestionCountCorrect +
                                        ' Correct'
                                      : 0 + ' Correct'}
                                  </p>
                                  <p>
                                    <UnpublishedIcon sx={{ color: 'red' }} />{' '}
                                    {chapter.Test.QuestionCountAnswered
                                      ? chapter.Test.QuestionCountAnswered -
                                        chapter.Test.QuestionCountCorrect
                                      : 0}{' '}
                                    Wrong
                                  </p>
                                </div>
                                <div className="result">
                                  <h6>Result:</h6>
                                  {chapter.Test.IsPassed ? (
                                    <p className="pass">Passed</p>
                                  ) : (
                                    <p className="fail">Failed</p>
                                  )}
                                </div>
                              </div>
                              <div className="line"></div>
                            </>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    studentData: state.studentData,
    commonData: state.commonData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(authActions, dispatch),
      ...bindActionCreators(studentDataActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Curriculum);
