import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import './index.css';

function NotificationMessageInner({ notification }) {
  return (
    <>
      <div className="error-icon">
        <ErrorOutlineIcon fontSize="large" />
      </div>
      {notification.Message}
    </>
  );
}

function NotificationMessage({ notification }) {
  return (
    <div
      className={
        'notification-message' +
        (notification.Route || notification.Url ? ' button' : '')
      }
    >
      {notification.Url ? (
        <Button href={notification.Url} target="_blank">
          <NotificationMessageInner notification={notification} />
        </Button>
      ) : (
        <Button component={Link} to={notification.Route}>
          <NotificationMessageInner notification={notification} />
        </Button>
      )}
    </div>
  );
}

export default NotificationMessage;
