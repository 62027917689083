import * as api from './apiConfig';

export function getStudentDashboard(userID) {
  return api
    .get(
      'API/StudentData/GetStudentDashboard' +
        (userID ? '?userID=' + userID : '')
    )
    .then(response => response.data);
}

export function listCurriculum(userID) {
  return api
    .get('API/StudentData/ListCurriculum' + (userID ? '?userID=' + userID : ''))
    .then(response => response.data);
}

export function getLessonVideo(
  sectionID,
  sectonChapterID,
  sectionChapterLessonID
) {
  return api
    .get(
      'API/StudentData/GetLessonVideo?sectionID=' +
        sectionID +
        '&sectonChapterID=' +
        sectonChapterID +
        '&sectionChapterLessonID=' +
        sectionChapterLessonID
    )
    .then(response => response.data.split('"').join(''));
}

export function updateLessonVideoTime(sectionChapterLessonID, time) {
  return api
    .post(
      'API/StudentData/UpdateLessonVideoTime?sectionChapterLessonID=' +
        sectionChapterLessonID +
        '&time=' +
        encodeURIComponent(time)
    )
    .then(response => response.data);
}

export function updateLessonVideoEnded(sectionChapterLessonID) {
  return api
    .post(
      'API/StudentData/UpdateLessonVideoEnded?sectionChapterLessonID=' +
        sectionChapterLessonID
    )
    .then(response => response.data);
}

export function getSectionTest(sectionChapterID, startOver) {
  return api
    .get(
      'API/StudentData/GetSectionTest?sectionChapterID=' +
        sectionChapterID +
        '&startOver=' +
        (startOver ? 'true' : 'false')
    )
    .then(response => response.data);
}

export function saveSectionTestAnswer(
  userTestID,
  sectionChapterQuestionID,
  sectionChapterQuestionAnswerID
) {
  return api
    .post(
      'API/StudentData/SaveSectionTestAnswer?userTestID=' +
        userTestID +
        '&sectionChapterQuestionID=' +
        sectionChapterQuestionID +
        '&sectionChapterQuestionAnswerID=' +
        sectionChapterQuestionAnswerID
    )
    .then(response => response.data);
}

export function getSectionPDF(fileName) {
  return api
    .get(
      'API/StudentData/GetSectionPDF?fileName=' + encodeURIComponent(fileName)
    )
    .then(response => response.data);
}

export function listAnnouncement() {
  return api
    .get('API/StudentData/ListAnnouncement')
    .then(response => response.data);
}
