import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdiThemeProvider from '../AdiThemeProvider';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  savePromoCode,
  deletePromoCode,
  listPromoCode
} from 'api/adminDataApi';

import './index.css';

function AdminPromoCodes({ student, closeFunc, onUserAdded }) {
  const [editPromoCode, setEditPromoCode] = useState(false);
  const [promoCodeList, setpromoCodeList] = useState(null);

  useEffect(() => {
    loadpromoCodeList();
  }, []);

  function loadpromoCodeList() {
    listPromoCode().then(_promoCodeList => setpromoCodeList(_promoCodeList));
  }

  const copyToClipboard = str => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
      return navigator.clipboard.writeText(str);
    return Promise.reject('The Clipboard API is not available.');
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      fullScreen={true}
      className="admin-register-popup">
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        Promo Codes
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '0px' }}>
        <>
          <div>
            <div className="announcement-side">
              {promoCodeList ? (
                promoCodeList.length ? (
                  promoCodeList.map(promoCode => (
                    <div className="announcement">
                      <div className="edit-button">
                        <IconButton
                          onClick={() => {
                            setEditPromoCode(null);
                            setTimeout(
                              () => setEditPromoCode({ ...promoCode }),
                              100
                            );
                          }}>
                          <EditIcon />
                        </IconButton>

                        {!promoCode.UserCount ? (
                          <IconButton
                            onClick={() => {
                              deletePromoCode(promoCode).then(result => {
                                loadpromoCodeList();
                              });
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        ) : null}
                      </div>

                      <div className="announcement-content">
                        <h6>{promoCode.PromoCodeName}</h6>
                        <p>
                          <strong>Code:</strong> {promoCode.PromoKey}
                          <br />
                          <strong>Users:</strong> {promoCode.UserCount}
                        </p>
                        <Button
                          style={{ marginTop: '0px' }}
                          variant="outlined"
                          // href={
                          //   'http://3di.autodealerinstitute.com/#/?promoCode=' +
                          //   promoCode.PromoKey
                          // }
                          // target="_blank"
                          onClick={() =>
                            copyToClipboard(
                              'http://3di.autodealerinstitute.com/#/?promoCode=' +
                                promoCode.PromoKey
                            )
                          }>
                          Copy {promoCode.PromoCodeName} Registration Link
                          &nbsp; &nbsp; <ContentCopyIcon />
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="empty">No Promo Codes have been added.</div>
                )
              ) : (
                <LinearProgress />
              )}
            </div>

            <div className="edit-add-announcement-side">
              <div className="add-button">
                {editPromoCode ? (
                  <>
                    <div className="add-announcement-header">
                      <p>
                        {editPromoCode.PromoCodeID === 0 ? 'Add' : 'Edit'} a
                        Promo Code
                      </p>
                    </div>
                    <TextField
                      id="standard-basic"
                      label="Promo Code Name"
                      placeholder="Enter a name to identify this promo code"
                      value={editPromoCode.PromoCodeName}
                      variant="standard"
                      fullWidth
                      className="text-field"
                      onChange={event =>
                        setEditPromoCode({
                          ...editPromoCode,
                          PromoCodeName: event.target.value
                        })
                      }
                    />

                    <TextField
                      id="standard-basic"
                      label="Code"
                      placeholder="Enter a unique code to for tracking behind the scenes"
                      value={editPromoCode.PromoKey}
                      variant="standard"
                      fullWidth
                      className="text-field"
                      onChange={event =>
                        setEditPromoCode({
                          ...editPromoCode,
                          PromoKey: event.target.value
                        })
                      }
                    />

                    <TextField
                      id="standard-basic"
                      label="Price"
                      type="number"
                      value={editPromoCode.Price}
                      variant="standard"
                      fullWidth
                      className="text-field"
                      placeholder="Enter 0.00 if payment is tracked externally"
                      onChange={event =>
                        setEditPromoCode({
                          ...editPromoCode,
                          Price: event.target.value
                        })
                      }
                    />

                    <TextField
                      id="standard-basic"
                      label="DocuSign PowerForms URL"
                      value={editPromoCode.DocuSignTemplate}
                      variant="standard"
                      fullWidth
                      className="text-field"
                      placeholder="Paste the URL for the for DocuSign"
                      onChange={event =>
                        setEditPromoCode({
                          ...editPromoCode,
                          DocuSignTemplate: event.target.value
                        })
                      }
                    />

                    <Tooltip title="When this is checked, the user has access to the dashboard after any payment or DocuSign requirements have been met. If not, checked, dashboard access will be disabled for any new users until an admin grants access.">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={editPromoCode.DefaultUserIsActive}
                            onChange={event =>
                              setEditPromoCode({
                                ...editPromoCode,
                                DefaultUserIsActive: event.target.checked
                              })
                            }
                          />
                        }
                        label={'Allow access to dashboard by default'}
                      />
                    </Tooltip>

                    <br />
                    <div className="save-button">
                      <AdiThemeProvider>
                        <Button
                          onClick={() => {
                            savePromoCode(editPromoCode).then(result => {
                              setEditPromoCode(null);
                              loadpromoCodeList();
                            });
                          }}
                          variant="contained"
                          color="neutral"
                          disabled={
                            !editPromoCode.PromoCodeName ||
                            !editPromoCode.PromoKey ||
                            isNaN(parseFloat(editPromoCode.Price))
                          }>
                          Save
                        </Button>
                      </AdiThemeProvider>
                    </div>

                    <Button
                      onClick={() => {
                        setEditPromoCode(null);
                      }}
                      variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      setEditPromoCode({ PromoCodeID: 0 });
                    }}
                    variant="outlined">
                    Add New Promo Code
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdminPromoCodes;
