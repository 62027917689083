import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import "./index.css";

function LessonIcon({ lesson }) {
  return lesson.IsCompleted ? (
    <div className="icon-container">
      <CheckCircleIcon sx={{ color: "#24cb27" }} />
    </div>
  ) : (
    <div className="icon-container">
      <CheckCircleIcon sx={{ color: "#ccc" }} />
    </div>
  );
}

export default LessonIcon;
