import React, { useEffect, useState, useRef } from 'react';

import './index.css';
import { getSessionStorage, setSessionStorage } from 'api/apiConfig';
import {
  TextField,
  Button,
  Alert,
  LinearProgress,
  IconButton
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import { listJobListing } from 'api/authApi';
import {
  ArrowDownward,
  ArrowUpward,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search
} from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';

function JobListingSearch() {
  const passwordRequired = 'password08';
  const passwordEntered = getSessionStorage('adi-pw');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [jobListingList, setJobListingList] = useState(null);
  const [filteredList, setFilteredList] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [expanded, setExpanded] = useState({});
  const [searchField, setSearchField] = useState('City');
  const [selectedState, setSelectedState] = useState('');

  useEffect(() => {
    setIsLoggedIn(passwordEntered === passwordRequired);
  }, [passwordEntered]);

  useEffect(() => {
    if (isLoggedIn && !jobListingList) {
      listJobListing(passwordEntered).then(_jobListingList =>
        setJobListingList(_jobListingList)
      );
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (jobListingList) {
      if (!filteredList) {
        setFilteredList(jobListingList);
      }
    }
  }, [jobListingList]);

  useEffect(() => {
    if (document.getElementById('searchString')) {
      document.getElementById('searchString').focus();
    }
  }, [searchField]);

  useEffect(() => {
    if (selectedState && searchField === 'State') {
      searchListings();
    }
  }, [selectedState]);

  function searchListings() {
    setFilteredList(
      jobListingList.filter(job => {
        let _searchCompare =
          searchField === 'State'
            ? selectedState.toLocaleLowerCase()
            : searchString.toLocaleLowerCase();
        let _value = '';
        switch (searchField) {
          case 'City':
            _value = String(job.Location).toLocaleLowerCase();
            break;
          case 'State':
            _value = String(job.State).toLocaleLowerCase();
            break;
          default:
            _value = String(job.Headline).toLocaleLowerCase();
            break;
        }

        return _value.indexOf(_searchCompare) !== -1;
      })
    );
  }

  return (
    <div className="job-board">
      {isLoggedIn ? (
        filteredList ? (
          <div className="listing-list">
            <div className="search-field">
              <Tabs
                value={searchField}
                onChange={(event, newValue) => setSearchField(newValue)}>
                <span className="title">Search by: </span>

                <Tab value="City" label="City" />
                <Tab value="State" label="State" />
                {/* <Tab value="Headline" label="Headline" /> */}
              </Tabs>

              {searchField === 'State' ? (
                <FormControl fullWidth>
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                    labelId="state-label"
                    id="state"
                    value={selectedState}
                    label="State"
                    onChange={event => setSelectedState(event.target.value)}>
                    {[
                      'AK',
                      'AL',
                      'AR',
                      'AZ',
                      'CA',
                      'CO',
                      'CT',
                      'DC',
                      'DE',
                      'FL',
                      'GA',
                      'HI',
                      'IA',
                      'ID',
                      'IL',
                      'IN',
                      'KS',
                      'KY',
                      'LA',
                      'MA',
                      'MD',
                      'ME',
                      'MI',
                      'MN',
                      'MO',
                      'MS',
                      'MT',
                      'NC',
                      'ND',
                      'NE',
                      'NH',
                      'NJ',
                      'NM',
                      'NV',
                      'NY',
                      'OH',
                      'OK',
                      'OR',
                      'PA',
                      'PR',
                      'RI',
                      'SC',
                      'SD',
                      'TN',
                      'TX',
                      'UT',
                      'VA',
                      'VI',
                      'VT',
                      'WA',
                      'WI',
                      'WV',
                      'WY'
                    ].map(state => (
                      <MenuItem value={state}>{state}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  fullWidth
                  value={searchString}
                  onChange={event => setSearchString(event.target.value)}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      searchListings();
                    }
                  }}
                  id="searchString"
                  variant="filled"
                  label={'Search listings by ' + searchField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          aria-label="search"
                          disabled={!searchString}
                          onClick={() => {
                            searchListings();
                          }}>
                          <Search />
                        </Button>
                      </InputAdornment>
                    )
                  }}
                />
              )}

              <div className="count">
                {filteredList.length === jobListingList.length
                  ? 'Showing all ' + filteredList.length + ' job listings'
                  : filteredList.length + ' results'}

                {filteredList.length !== jobListingList.length && (
                  <Button
                    onClick={() => {
                      setSearchString('');
                      setSelectedState('');
                      setFilteredList(jobListingList);
                    }}>
                    Clear Search
                  </Button>
                )}
              </div>
            </div>

            {filteredList.map(job => (
              <div key={job.JobListingID} className="card">
                <a href={job.Url} target="_blank" className="link">
                  View Listing
                  <ChevronRight />
                </a>

                {/* <h2>
                  <a href={job.Url} target="_blank">
                    {job.Headline}
                  </a>
                </h2> */}
                <h3>
                  Location: {job.Location}
                  {job.State ? ', ' + job.State : ''}
                </h3>
                <p
                  className={
                    'description' +
                    (expanded[job.JobListingID] ? ' expanded' : '')
                  }>
                  {job.Description}
                  {/* <Button
                    onClick={() =>
                      setExpanded({
                        ...expanded,
                        [job.JobListingID]: !expanded[job.JobListingID]
                      })
                    }>
                    {expanded[job.JobListingID] ? (
                      <>
                        Show Less <KeyboardArrowUp />
                      </>
                    ) : (
                      <>
                        Show More <KeyboardArrowDown />
                      </>
                    )}
                  </Button> */}
                </p>

                {job.DatePosted && (
                  <em>Posted: {moment(job.DatePosted).format('MM/DD/YYYY')}</em>
                )}
                {job.DateExpired && (
                  <em>
                    Valid Until: {moment(job.DateExpired).format('MM/DD/YYYY')}
                  </em>
                )}
              </div>
            ))}
          </div>
        ) : (
          <LinearProgress />
        )
      ) : (
        <div className="listing-login">
          {passwordInvalid && (
            <Alert severity="error" style={{ marginBottom: '20px' }}>
              Enter a valid password
            </Alert>
          )}
          <form
            autoComplete="false"
            onSubmit={() => {
              setPasswordInvalid(false);

              if (password === passwordRequired) {
                setSessionStorage('adi-pw', password);
                setIsLoggedIn(true);
              } else {
                setPasswordInvalid(true);
              }
            }}>
            <TextField
              value={password}
              autoComplete="new-password"
              onChange={event => setPassword(event.target.value)}
              variant="filled"
              label="Enter Password"
              type="password"
              fullWidth
              inputProps={{ autoComplete: 'off' }}
            />
            <br />
            <Button
              variant="contained"
              style={{ marginTop: '20px' }}
              disabled={!password}
              type="submit">
              Log In
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}

export default JobListingSearch;
