import React from 'react';

import { getProfileImagePath } from '../../api/authApi';

function ProfileImage(props) {
  const student = props.data;

  return (
    <div className="profile-image-container">
      <div
        className="user-image"
        style={{
          //backgroundColor: "#" + student.ColorCode,
          backgroundImage:
            student && student.UserID && student.ProfileImage
              ? "url('" + getProfileImagePath(student.ProfileImage) + "')"
              : ''
        }}>
        {student && student.UserID && !student.ProfileImage ? (
          <div className="user-image-initials">
            {student.FirstName.charAt(0) + student.LastName.charAt(0)}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ProfileImage;
