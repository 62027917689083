import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import AdiThemeProvider from '../AdiThemeProvider';
import InputMask from 'react-input-mask';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import * as authActions from '../../redux/actions/authActions';
import { listPromoCode } from '../../api/adminDataApi';
import { registerUser } from '../../api/authApi';
import { getCookie, setCookie } from '../../api/apiConfig';
import { validateEmail, validatePassword } from '../CommonFunctions';

import './index.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RegistrationForm = ({
  auth,
  actions,
  activeStep,
  setActiveStep,
  doLogin,
  isAdmin,
  onRegistration
}) => {
  let query = useQuery();
  const [promoCode, setPromoCode] = useState(
    query.get('promoCode')
      ? query.get('promoCode')
      : getCookie('promoCode')
      ? getCookie('promoCode')
      : ''
  );
  const [promoCodeList, setPromoCodeList] = useState(null);

  const [newUser, setNewUser] = useState({});

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  });

  console.log('promoCode', promoCode);

  useEffect(() => {
    if (isAdmin) {
      listPromoCode().then(_promoCodeList => setPromoCodeList(_promoCodeList));
    }
  }, []);

  // useEffect(() => {
  //   if (!promoCode && activeStep === 'Register') {
  //     window.location =
  //       'https://www.autodealerinstitute.com/coupon-code-assistance';
  //   }
  // }, [activeStep]);

  useEffect(() => {
    if (promoCode) {
      setCookie('promoCode', promoCode);
    }
  }, [promoCode]);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <>
      {newUser.error ? <p className="error">{newUser.error}</p> : null}

      {!isAdmin ? (
        <>
          <h1>Welcome!</h1>

          <p className="welcome-para">
            Complete the form below and we’ll email you enrollment documents to
            complete and sign using DocuSign, the #1 trusted eSignature
            platform. Your records are digitally sealed and secured with layers
            of security and authentication.
          </p>
        </>
      ) : (
        <>
          <p className="welcome-para">
            The student will receive their login information in their email.
          </p>

          {promoCodeList ? (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Promo Code</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={promoCode}
                label="Promo Code"
                fullWidth
                onChange={event => setPromoCode(event.target.value)}>
                {promoCodeList.map(_promoCode => (
                  <MenuItem value={_promoCode.PromoKey}>
                    {_promoCode.PromoCodeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </>
      )}

      <TextField
        id="standard-basic"
        label="First Name"
        variant="standard"
        className="text-field"
        fullWidth
        value={newUser.firstName}
        onChange={event =>
          setNewUser({
            ...newUser,
            firstName: event.target.value
          })
        }
      />

      <TextField
        id="standard-basic"
        label="Last Name"
        variant="standard"
        fullWidth
        className="text-field"
        value={newUser.lastName}
        onChange={event =>
          setNewUser({
            ...newUser,
            lastName: event.target.value
          })
        }
      />

      <TextField
        id="standard-basic"
        label="Enter Email"
        type="email"
        variant="standard"
        className="text-field"
        fullWidth
        value={newUser.email}
        onChange={event =>
          setNewUser({
            ...newUser,
            email: event.target.value
          })
        }
      />

      <InputMask
        mask={'999-999-9999'}
        value={newUser.phone}
        onChange={event =>
          setNewUser({
            ...newUser,
            phone: event.target.value
          })
        }>
        {() => (
          <TextField
            placeholder={'555-555-5555'}
            variant="standard"
            fullWidth={true}
            id="standard-basic"
            label="Enter Cell Phone Number"
            type="phone"
            className="text-field"
            helperText="We'll be sending you a mobile verification code"
            value={newUser.phone}
          />
        )}
      </InputMask>

      <TextField
        id="standard-password-input"
        label="Enter Password"
        type={values.showPassword ? 'text' : 'password'}
        autoComplete="current-password"
        variant="standard"
        className="text-field"
        fullWidth
        value={newUser.password}
        onChange={event =>
          setNewUser({
            ...newUser,
            password: event.target.value
          })
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}>
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      {!isAdmin ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={newUser.checkedBox}
              onChange={event =>
                setNewUser({
                  ...newUser,
                  checkedBox: event.target.checked
                })
              }
            />
          }
          className="checkbox"
          label={
            <>
              By signing up, you agree to <a href="/about">Terms of Service</a>{' '}
              and <a href="/about">Privacy Policy</a>
            </>
          }
        />
      ) : null}

      <AdiThemeProvider>
        <Button
          className="create-account-button"
          variant="contained"
          color="neutral"
          disabled={
            !newUser.firstName ||
            !newUser.lastName ||
            !newUser.email ||
            !validateEmail(newUser.email) ||
            !validatePassword(newUser.password) ||
            !newUser.password ||
            !newUser.phone ||
            newUser.phone.indexOf('_') !== -1 ||
            (!isAdmin && !newUser.checkedBox)
          }
          onClick={event => {
            actions.setDataLoading(true, 'Creating your account...');
            registerUser(
              promoCode,
              newUser.email,
              newUser.password,
              newUser.firstName,
              newUser.lastName,
              newUser.phone,
              isAdmin
            ).then(result => {
              console.log('registerUser result', result);
              if (result && result.UserID) {
                if (onRegistration) {
                  onRegistration();
                  actions.setDataLoading(false);
                } else {
                  //If the user registered successfully, go ahead and log them in with the known credentials
                  doLogin(newUser.email, newUser.password).then(result =>
                    actions.setDataLoading(false)
                  );
                }
              } else {
                actions.setDataLoading(false);
                setNewUser({
                  ...newUser,
                  error: result.AppMessage
                });
              }
            });
          }}>
          Create Account
        </Button>
      </AdiThemeProvider>

      {!isAdmin ? (
        <p className="already-member">
          Already a member?{' '}
          <a
            onClick={event => {
              setActiveStep('Login');
            }}>
            Login here.
          </a>
        </p>
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
