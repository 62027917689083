import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case types.AUTH_DEVICE_READY:
      return { ...state, device: { ...action.device } };
    case types.AUTH_USER_LOGIN:
      return { ...state, user: { ...action.user } };
    case types.AUTH_DATA_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        loadingMessage: action.loadingMessage,
      };
    default:
      return state;
  }
}
