import { combineReducers } from "redux";
import auth from "./authReducer";
import commonData from "./commonDataReducer";
import studentData from "./studentDataReducer";

const rootReducer = combineReducers({
  auth,
  studentData,
  commonData,
});

export default rootReducer;
