import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

import {
  getSectionTest,
  saveSectionTestAnswer
} from '../../api/studentDataApi';

import * as studentDataActions from '../../redux/actions/studentDataActions';
import * as authActions from '../../redux/actions/authActions';

import AdiThemeProvider from '../AdiThemeProvider';

import './index.css';

function Test({ auth, actions, studentData, activeTest, setActiveTest }) {
  const [testData, setTestData] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [viewAnswerSheet, setViewAnswerSheet] = useState(false);
  const [answerSheetButtonText, setAnswerSheetButtonText] = useState(
    'See Answer Sheet'
  );

  useEffect(() => {
    loadTestData();
  }, []);

  useEffect(() => {
    console.log('testData', testData);
    if (
      !activeQuestion &&
      testData &&
      testData.QuestionList &&
      testData.QuestionList.length
    ) {
      const nextQuestion = testData.QuestionList.filter(
        question =>
          !question.UserAnswerID &&
          (!activeQuestion ||
            activeQuestion.SectionChapterQuestionID !==
              question.SectionChapterQuestionID)
      )[0];
      setActiveQuestion(nextQuestion ? nextQuestion : testData.QuestionList[0]);
    }
  }, [testData]);

  function setNextQuestion() {
    const nextQuestion = testData.QuestionList.filter(
      question => question.QuestionNumber > activeQuestion.QuestionNumber
    )[0];
    setActiveQuestion(nextQuestion ? nextQuestion : testData.QuestionList[0]);
  }

  function checkIsLastQuestion() {
    console.log(
      'checkIsLastQuestion',
      testData.QuestionList.filter(question => !question.UserAnswerID)
    );

    return (
      testData.QuestionList.filter(question => !question.UserAnswerID)
        .length === 1
    );
  }

  function loadTestData(startOver) {
    getSectionTest(activeTest.SectionChapterID, startOver).then(test =>
      setTestData(test)
    );
  }

  function retakeTest() {
    setViewAnswerSheet(false);
    setActiveQuestion(null);
    loadTestData(true);
  }

  return testData ? (
    <>
      <div className="arrow-back">
        <div className="test-title">
          <h5>
            {testData.Header.SectionName} <ChevronRightIcon /> Exam
          </h5>
        </div>
      </div>
      {!testData.Header.IsComplete ? (
        <>
          <div className="test-top-container">
            <div className="test-container">
              {activeQuestion ? (
                <div className="test">
                  <div className="question-buttons">
                    <p>
                      Question {activeQuestion.QuestionNumber} out of{' '}
                      {testData.QuestionList.length}
                    </p>

                    {testData.QuestionList.map(question => (
                      <AdiThemeProvider key={question.SectionChapterQuestionID}>
                        <Button
                          className={
                            'question-button' +
                            (question.SectionChapterQuestionID ===
                            activeQuestion.SectionChapterQuestionID
                              ? ' active'
                              : '')
                          }
                          variant="contained"
                          color={
                            question.UserAnswerID ? 'answered' : 'notAnswered'
                          }
                          onClick={event => setActiveQuestion(question)}>
                          {question.QuestionNumber}
                        </Button>
                      </AdiThemeProvider>
                    ))}
                  </div>

                  <div className="questions">
                    <h5>{activeQuestion.Question}</h5>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={activeQuestion.UserAnswerID}
                      name="radio-buttons-group"
                      onChange={event =>
                        setActiveQuestion({
                          ...activeQuestion,
                          UserAnswerID: event.target.value
                        })
                      }>
                      {activeQuestion.AnswerList.map(answer => (
                        <FormControlLabel
                          key={answer.SectionChapterQuestionAnswerID}
                          value={answer.SectionChapterQuestionAnswerID}
                          control={<Radio />}
                          label={answer.Answer}
                        />
                      ))}
                    </RadioGroup>
                  </div>

                  <div className="two-buttons">
                    <AdiThemeProvider>
                      <Button
                        className="continue-button"
                        variant="contained"
                        color="neutral"
                        disabled={!activeQuestion.UserAnswerID}
                        onClick={event => {
                          saveSectionTestAnswer(
                            testData.Header.UserTestID,
                            activeQuestion.SectionChapterQuestionID,
                            activeQuestion.UserAnswerID
                          ).then(testHeader => {
                            if (testHeader.IsComplete) {
                              loadTestData();
                              actions.loadCurriculum();
                            } else {
                              setTestData({
                                ...testData,
                                Header: testHeader,
                                QuestionList: testData.QuestionList.map(
                                  question =>
                                    question.SectionChapterQuestionID ===
                                    activeQuestion.SectionChapterQuestionID
                                      ? {
                                          ...question,
                                          UserAnswerID:
                                            activeQuestion.UserAnswerID
                                        }
                                      : { ...question }
                                )
                              });
                              setNextQuestion();
                            }
                          });
                        }}>
                        {checkIsLastQuestion() ? 'Finish' : 'Continue'}
                      </Button>
                    </AdiThemeProvider>

                    {checkIsLastQuestion() ? null : (
                      <AdiThemeProvider>
                        <Button
                          className="skip-button"
                          variant="contained"
                          color="white"
                          onClick={event => {
                            setNextQuestion();
                          }}>
                          Skip Question
                        </Button>
                      </AdiThemeProvider>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : !testData.Header.IsPassed ? (
        <div className="top-test-results">
          <div className="test-results-title">
            <h5>Your score needs to be 70% or higher.</h5>
            <h5>Please study this chapter and retake the test.</h5>
          </div>
          <div className="score-rating">
            <div className="left">
              <p className="score-title">Exam Score:</p>
              <div className="result">
                <CheckCircleIcon sx={{ color: '#24cb27' }} />
                <p>{testData.Header.QuestionCountCorrect} Correct</p>
              </div>
              <div className="result">
                <UnpublishedIcon sx={{ color: 'red' }} />
                <p>
                  {testData.Header.QuestionCountTotal -
                    testData.Header.QuestionCountCorrect}{' '}
                  Wrong
                </p>
              </div>
            </div>
            <div className="right">
              <p className="score-title">Rating:</p>
              <p className="fail-test-score">{testData.Header.GradeLetter}</p>
            </div>
          </div>
          <AdiThemeProvider>
            <Button
              className="done-button"
              onClick={event => retakeTest()}
              variant="contained"
              color="neutral">
              Retake
            </Button>
          </AdiThemeProvider>
          <AdiThemeProvider>
            <Button
              className="answer-sheet-button"
              variant="contained"
              color="white"
              onClick={event => {
                setViewAnswerSheet(true);
              }}>
              {answerSheetButtonText}
            </Button>
          </AdiThemeProvider>
        </div>
      ) : (
        <div className="top-test-results">
          <div className="test-results-title">
            <h5>Congratulations! You have successfully passed</h5>
            <h5>{testData.Header.SectionChapterName}</h5>
          </div>
          <div className="score-rating">
            <div className="left">
              <p className="score-title">Exam Score:</p>
              <div className="result">
                <CheckCircleIcon sx={{ color: '#24cb27' }} />
                <p>{testData.Header.QuestionCountCorrect} Correct</p>
              </div>
              <div className="result">
                <UnpublishedIcon sx={{ color: 'red' }} />
                <p>
                  {testData.Header.QuestionCountTotal -
                    testData.Header.QuestionCountCorrect}{' '}
                  Wrong
                </p>
              </div>
            </div>
            <div className="right">
              <p className="score-title">Rating:</p>
              <p className="pass-test-score">{testData.Header.GradeLetter}</p>
            </div>
          </div>
          <AdiThemeProvider>
            <Button
              className="done-button"
              onClick={event => setActiveTest(null)}
              variant="contained"
              color="neutral">
              Curriculum
            </Button>
          </AdiThemeProvider>
          <AdiThemeProvider>
            <Button
              className="answer-sheet-button"
              variant="contained"
              color="white"
              onClick={event => {
                if (answerSheetButtonText === 'See Answer Sheet') {
                  setViewAnswerSheet(true);
                  setAnswerSheetButtonText('Hide Answer Sheet');
                } else {
                  setViewAnswerSheet(false);
                  setAnswerSheetButtonText('See Answer Sheet');
                }
              }}>
              {answerSheetButtonText}
            </Button>
          </AdiThemeProvider>
        </div>
      )}

      {viewAnswerSheet ? (
        <div className="answer-sheet-container">
          <div className="answer-sheet-content">
            <div className="questions">
              {testData.QuestionList.map(question => (
                <>
                  <div className="each-question">
                    <h5>{question.Question}</h5>

                    <ul>
                      {question.AnswerList.map(answer => (
                        <li key={answer.SectionChapterQuestionAnswerID}>
                          <div className="circle">
                            {answer.IsCorrectAnswer ? (
                              <CheckCircleIcon sx={{ color: '#24cb27' }} />
                            ) : null}
                            {!answer.IsCorrectAnswer && answer.IsUserAnswer ? (
                              <UnpublishedIcon sx={{ color: 'red' }} />
                            ) : null}
                          </div>
                          {answer.Answer}
                        </li>
                      ))}
                    </ul>
                    {question.UserAnswerID &&
                    question.AnswerList.filter(
                      answer => answer.IsCorrectAnswer && answer.IsUserAnswer
                    ).length ? (
                      <img
                        className="answer-icon"
                        src="/images/correct-icon.svg"
                        alt="Correct"></img>
                    ) : (
                      <img
                        className="answer-icon"
                        src="/images/wrong-icon.svg"
                        alt="Wrong"></img>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    studentData: state.studentData,
    commonData: state.commonData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(authActions, dispatch),
      ...bindActionCreators(studentDataActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Test);
