import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { LinearProgress } from '@mui/material';

import { trackDocSigned } from '../../api/authApi';

import * as authActions from '../../redux/actions/authActions';

import StudentDashboard from '../StudentDashboard';

function DocuSignComplete({ actions, auth }) {
  useEffect(() => {
    const reference = window.location.href.split('?')[1];
    if (reference) {
      trackDocSigned(reference).then(result => actions.checkAuthToken());
    }
  }, []);

  return auth && auth.user && auth.user.IsDocSigned ? (
    <StudentDashboard />
  ) : (
    <LinearProgress />
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    studentData: state.studentData,
    commonData: state.commonData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(authActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocuSignComplete);
