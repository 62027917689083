import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import RegistrationForm from '../RegistrationForm';

import './index.css';

function AdminStudentRegistrationPopup({ student, closeFunc, onUserAdded }) {
  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="admin-register-popup">
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        Add New User
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <RegistrationForm isAdmin onRegistration={onUserAdded} />
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdminStudentRegistrationPopup;
