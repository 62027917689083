import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress
} from '@mui/material';
import { getApiUrl, getAuthHeaders } from '../../api/apiConfig';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '../Grid';
import GridButton from '../Grid/GridButton';
import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper
} from '@mui/material';

import {
  deleteJobListing,
  importJobListing,
  listJobListing
} from '../../api/adminDataApi';

import './index.css';

function JobListingPopup({ closeFunc }) {
  const gridApi = useRef();
  const columnApi = useRef();

  const [jobListingList, setJobListingList] = useState(null);
  const [showImport, setShowImport] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importResult, setImportResult] = useState(null);
  const [promptDelete, setPromptDelete] = useState(null);

  useEffect(() => {
    if (!showImport) {
      setImportResult(null);
      setImporting(false);
    }
  }, [showImport]);

  function loadJobListingList() {
    listJobListing().then(result => {
      setJobListingList(result);
    });
  }

  function onGridReady(params) {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    loadJobListingList();
  }

  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        className="job-listing-list-popup"
        fullScreen>
        <DialogTitle id="form-dialog-title">
          <IconButton
            style={{ float: 'right' }}
            aria-label="close"
            onClick={() => {
              closeFunc();
            }}>
            <CloseIcon />
          </IconButton>
          Job Listings
        </DialogTitle>
        <DialogContent width="100%" style={{ paddingTop: '20px' }}>
          <Grid
            columnDefs={[
              { field: 'Headline', width: 300 },
              { field: 'Location' },
              { field: 'DatePosted', dataType: 'date' },
              { field: 'DateExpired', dataType: 'date' },
              { field: 'State' },
              { field: 'Description', width: 900 },
              {
                field: 'Delete',
                headerName: 'Delete',
                cellRendererFramework: GridButton,
                onCellClicked: function(params) {
                  setPromptDelete(params.data);
                },
                valueGetter: function(params) {
                  return 'Delete';
                },
                width: 100
              }
            ]}
            rowData={jobListingList}
            onGridReady={onGridReady}
            immutableData={true}
            getRowNodeId={data => data.JobListingID}
            getRowClass={params => {
              return params.data.IsExpired ? 'disabled' : '';
            }}
            refreshFunc={loadJobListingList}>
            <div className="param-group">
              <Button
                variant="contained"
                style={{ width: '140px' }}
                onClick={() => setShowImport(true)}>
                Import Listings
              </Button>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions style={{ display: 'block', textAlign: 'right' }}>
          <Button
            onClick={() => {
              closeFunc();
            }}
            variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {showImport && (
        <Dialog
          open={true}
          aria-labelledby="form-dialog-title"
          className="job-listing-list-popup">
          <DialogTitle id="form-dialog-title">
            <IconButton
              style={{ float: 'right' }}
              aria-label="close"
              onClick={() => {
                setShowImport(false);
              }}>
              <CloseIcon />
            </IconButton>
            Import Job Listings
          </DialogTitle>
          <DialogContent width="100%" style={{ paddingTop: '20px' }}>
            {importResult ? (
              <div className="import-result">
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" stickyHeader={true}>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={2} style={{ padding: '0px' }}>
                          {importResult && importResult.AppMessage ? (
                            <Alert severity="error">
                              {importResult.AppMessage}
                            </Alert>
                          ) : (
                            <Alert severity="success">
                              Import was successful!
                            </Alert>
                          )}
                        </TableCell>
                      </TableRow>
                      {Object.keys(importResult.Details)
                        .filter(detailKey => detailKey !== 'AppMessage')
                        .map((detailKey, detailIndex) => (
                          <TableRow key={'row-' + detailIndex}>
                            <TableCell key={'cell-label-' + detailIndex}>
                              {detailKey}
                            </TableCell>
                            <TableCell key={'cell-value-' + detailIndex}>
                              <strong>{importResult.Details[detailKey]}</strong>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : importing ? (
              <LinearProgress />
            ) : (
              <>
                <p>
                  To import job listings, please upload the file using the field
                  below.
                </p>
                <Dropzone
                  getUploadParams={() => {
                    return {
                      url: getApiUrl() + 'FileUpload/JobListingSpreadsheet',
                      headers: getAuthHeaders()
                    };
                  }}
                  onChangeStatus={({ meta, remove, xhr }, status) => {
                    if (status == 'done') {
                      setImportResult(null);
                      setImporting(false);

                      try {
                        const result = xhr.response;
                        const fileName = result.split('fileName:')[1];
                        if (fileName) {
                          setImporting(true);
                          importJobListing(fileName).then(result => {
                            setImportResult(result);

                            loadJobListingList();
                          });

                          remove();
                        }
                      } catch (e) {}
                    } else if (status === 'aborted') {
                      //toast(`${meta.name}, upload failed...`);
                    }
                  }}
                  maxFiles={1}
                  accept=".xlsx"
                  multiple={false}
                  canCancel={false}
                  inputContent="Click or drop file here to upload"
                />
              </>
            )}
          </DialogContent>
          <DialogActions style={{ display: 'block', textAlign: 'right' }}>
            <Button
              onClick={() => {
                setShowImport(false);
              }}
              variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {promptDelete && (
        <Dialog
          open={true}
          aria-labelledby="form-dialog-title"
          className="job-listing-delete-popup">
          <DialogTitle id="form-dialog-title">
            <IconButton
              style={{ float: 'right' }}
              aria-label="close"
              onClick={() => {
                setPromptDelete(null);
              }}>
              <CloseIcon />
            </IconButton>
            Delete Job Listing
          </DialogTitle>
          <DialogContent width="100%" style={{ paddingTop: '20px' }}>
            <p>Are you sure you want to delete this listing?</p>
          </DialogContent>
          <DialogActions style={{ display: 'block', textAlign: 'right' }}>
            <Button
              onClick={() => {
                deleteJobListing(promptDelete.JobListingID).then(result => {
                  setPromptDelete(null);
                  loadJobListingList();
                });
              }}
              variant="contained"
              color="primary">
              Confirm Delete
            </Button>
            <Button
              onClick={() => {
                setPromptDelete(null);
              }}
              variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default JobListingPopup;
